import axios from 'axios';
import { t, addLocale, useLocale as updateLocale } from 'ttag';

export const ISO_LOCALE_MAP = {
  en: 'en-US',
  fr_CA: 'fr-CA',
  de: 'de-DE',
  pt_BR: 'pt-BR',
  es: 'es-ES',
};

export const LANGUAGE_BY_ISO = (code: string) =>
  ({
    en: t`English`,
    fr_CA: t`French (Canada)`,
    de: t`German`,
    pt_BR: t`Portuguese`,
    es: t`Spanish`,
  }[code]);

export const getDefaultLanguage = () => {
  const browserLanguage = navigator.language.replace('-', '_');
  const hasSupportedLang = LANGUAGE_BY_ISO(browserLanguage);
  return hasSupportedLang ? browserLanguage : 'en';
};

export const setLocale = (locale: string, suffix: string = ''): Promise<void> => {
  if (locale === 'en') {
    updateLocale(locale);
    return Promise.resolve();
  }
  return axios.get(`/translations/${locale}${suffix}.json`).then(response => {
    addLocale(locale, response.data);
    updateLocale(locale);
  });
};
