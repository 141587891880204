/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { Configuration } from '../services/config/types';

interface State {
  crmId: string;
  id: string;
  isInternal: boolean;
  name: string;
  licenseType: string;
  contract: {
    id: string;
    startdate: string;
    endDate: string;
    status: string;
  };
  configuration: Configuration[];
}

const initialState: State = {
  crmId: null,
  id: null,
  isInternal: false,
  name: null,
  licenseType: null,
  configuration: [],
  contract: null,
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    unsetOrganization(state) {
      Object.assign(state, initialState);
    },
    setOrganization: {
      prepare(organization = {}) {
        const {
          crmOrganizationId: crmId,
          isInternal = false,
          id,
          name,
          licenseType,
          configuration,
        } = organization;

        const payload = {
          crmId,
          id,
          isInternal,
          name,
          licenseType,
          configuration,
        };

        return { payload, meta: null, error: null };
      },
      reducer(state, { payload }) {
        state.crmId = payload.crmId;
        state.id = payload.id;
        state.isInternal = payload.isInternal;
        state.name = payload.name;
        state.licenseType = payload.licenseType;
        state.configuration = payload.configuration;
      },
    },
    setContract: {
      prepare(contract = {}) {
        return {
          payload: contract ?? null,
          meta: null,
          error: null,
        };
      },
      reducer(state, { payload }) {
        state.contract = payload;
      },
    },
  },
});

export default slice.reducer;

export const getOrganization = state => state[slice.name];

export const getId = (state: State): string => state[slice.name]?.id;
export const getName = (state: State): string => state[slice.name]?.name;
export const getCrmId = (state: State): string => state[slice.name]?.crmId;
export const getLicenseType = (state: State) => state[slice.name]?.licenseType;
export const getConfiguration = (state: State): string => state[slice.name]?.configuration;
export const getContract = (state: State) => state[slice.name]?.contract;

export const { unsetOrganization, setOrganization, setContract } = slice.actions;
