import { createGlobalStyle } from 'styled-components';
import { lighten, darken } from 'polished';

import { Theme } from './types';

// Overrides for Antd's Button Component's Styles
//
// In case of a requirement where we may have to update the behavior as
// well, we should probably rename this file to `Button.jsx` and convert
// this to a Button Component along with following styles.
//
// Note: This is not supposed to be used directly. It is required to be
// composed with `ThemeProvider` from `styled-components`.
export default createGlobalStyle<Theme>`
button.ant-btn:not(.ant-btn-text), a.ant-btn:not(.ant-btn-text) {
  /* overriding styles for default button */
  border-radius: 4px;
  &:not(.ant-btn-primary):not(.ant-btn-danger):not(.ant-btn-dangerous):not(.ant-btn-link):not(.ant-btn-staff) {
    /*
    reason we have this scary looking selector is that Button's
    default behaviour is applied by the absence of these classes
    */
    &:hover:not([disabled]),
    &:focus:not([disabled]) {
      /* we don't want a disabled button to have hover or focus behaviour, do we? */
      color: ${({ theme: { primaryColor } }) => primaryColor};
      border-color: ${({ theme: { primaryColor } }) => primaryColor};
    }

    &:active {
      color: ${({ theme: { primaryColor } }) => darken(0.1, primaryColor)};
      border-color: ${({ theme: { primaryColor } }) => darken(0.1, primaryColor)};
    }
  }

  /* overriding styles for primary button when it's not disabled */
  &.ant-btn-primary:not([disabled]):not(.ant-btn-danger):not(.ant-btn-dangerous):not(.ant-btn-disabled) {
    background-color: ${({ theme: { primaryColor } }) => primaryColor};
    border-color: ${({ theme: { primaryColor } }) => primaryColor};

    &:hover,
    &:focus {
      background-color: ${({ theme: { primaryColor } }) => lighten(0.1, primaryColor)};
      border-color: ${({ theme: { primaryColor } }) => lighten(0.1, primaryColor)};
      color: #fff;
    }

    &:active {
      background-color: ${({ theme: { primaryColor } }) => darken(0.1, primaryColor)};
      border-color: ${({ theme: { primaryColor } }) => darken(0.1, primaryColor)};
      color: #fff;
    }
  }

  /* with shape being "round", Antd changes the font-size to 16px; following snippet reset it */
  &.ant-btn-round {
    font-size: 14px;
  }

   /* custom type for admin/staff button and it could be used as <Button type="staff" /> */
   &.ant-btn-staff:not([disabled]) {
    background-color: #d41968;
    border-color: #d41968;
    color: #fff;

    &:hover,
    &:focus {
      background-color: ${lighten(0.1, '#d41968')};
      border-color: ${lighten(0.1, '#d41968')};
      color: #fff;
    }

    &:active {
      background-color: ${darken(0.1, '#d41968')};
      border-color: ${darken(0.1, '#d41968')};
      color: #fff;
    }
  }
}
`;
