import axios, { AxiosResponse } from 'axios';
import { AppcuesLaunchpadFlow, AppcuesLaunchpad } from './types';

const Appcues = axios.create({
  baseURL: `https://api.appcues.com/v1`,
});

// category for a flow is extracted from flow name
export enum Category {
  ANNOUNCEMENTS = 'announcements',
  WALKTHROUGHS = 'walkthroughs',
}

const CATEGORY_SEPARATOR = ':';

// tranform mainly extracts the category from name which is divided by `NAME_SEPARATOR`
// If there is no NAME_SEPARATOR, or the extracted category is empty string, we mark it
// as CATEGORY_ROOT.
//
// Documentation: https://docs.appcues.com/en_US/dev-extras/1627003-launchpad-custom-install
const transform = (data: AppcuesLaunchpad): AppcuesLaunchpadFlow[] =>
  data.contents.map(({ id, name, createdAt, updatedAt }) => {
    let category = null;
    let formattedName = name;

    if (name.includes(CATEGORY_SEPARATOR)) {
      const [newCategory, ...splittedName] = name.split(CATEGORY_SEPARATOR);
      if (newCategory.trim()) {
        category = newCategory.trim().toLowerCase();
      }
      formattedName = splittedName.join(CATEGORY_SEPARATOR).trim();
    }

    return {
      id,
      name: formattedName,
      category,
      createdAt,
      updatedAt,
    };
  });

export const getLaunchpad = async (accountId: string, userId: number, url: string) => {
  const res: AxiosResponse<AppcuesLaunchpad> = await Appcues.get(
    `/accounts/${accountId}/users/${userId}/launchpad`,
    {
      params: { url },
    }
  );

  return transform(res.data);
};
