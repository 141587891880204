import * as Sentry from '@sentry/react';

declare const COMPANY_SENTRY_DSN: string;
declare const PRODUCTION: string;
declare const RELEASE_INFO: string;
declare const ENV: string;

const isPreview = !!RELEASE_INFO.match(/-preview#\d+/);

Sentry.init({
  enabled: !isPreview,
  dsn: COMPANY_SENTRY_DSN,
  release: RELEASE_INFO,
  environment: ENV,
  sampleRate: PRODUCTION ? 0.25 : 1,
  ignoreErrors: [
    // Following error is caused by weird chrome extension, ignore
    'Extension context invalidated.',

    // Following error is caused by ad blocker, there's nothing we can do
    // in this case
    'Resource blocked by content blocker',

    // Error codes we can safely ignore; if there is a problem, it's probably not
    // on our side
    /Request failed with status code 401/,
    /Request failed with status code 403/,
    /Request failed with status code 404/,
    /Request failed with status code 409/,

    // The request was dropped, the user is offline, etc.
    /Network ?Error/,
    /Request aborted/,
  ],
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (hint && breadcrumb.data && breadcrumb.category === 'xhr') {
      try {
        const response = JSON.parse(hint.xhr.response);
        const requestId = response.meta?.request_id ?? response.meta?.request?.request_id;

        if (requestId) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data.requestId = requestId;
        }

        if (breadcrumb.data.status_code >= 400) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data.errors = response.errors;
        }
      } catch {
        // do nothing
      }
    }

    return breadcrumb;
  },
});
