import React from 'react';
import { t } from 'ttag';
import { Button } from 'antd';

import useTranslation from '../../hooks/useTranslation';
import Layout from './Layout';

interface Props {
  scoped?: boolean;
  ctaText?: string;
  ctaHref?: string;
}

const HTTP404 = ({ scoped = false, ctaText = 'Go to Home', ctaHref = '/' }: Props) => {
  useTranslation();
  return (
    <Layout
      scoped={scoped}
      title={t`Page not found`}
      body={t`The page you requested could not be found. Please double check the URL and try again or contact our support team for assistance.`}
    >
      {ctaText && ctaHref ? (
        <Button type="primary" onClick={() => window.location.replace(ctaHref)}>
          {ctaText}
        </Button>
      ) : null}
    </Layout>
  );
};

export default HTTP404;
