import { createQueryDefinition, createQueryHook } from 'common/services/queryBuilder';

import { list as listFn } from './api';

export const GetAdminCompanyQuery = createQueryDefinition(
  () => ['company-events'] as const,
  () => listFn()
);

export const useCompanyEventsQuery = createQueryHook(GetAdminCompanyQuery, {
  select: ({ data }) => data,
});
