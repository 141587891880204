import React, { useLayoutEffect, useState } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import { isAuthenticated as isAuthenticatedFn } from '../../services/auth/api';

interface Props extends RouteProps {
  children: React.ReactNode;
}

const PublicRoute = ({ children, ...rest }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  useLayoutEffect(() => {
    async function effect() {
      const auth = await isAuthenticatedFn();
      if (auth) {
        history.replace('/');

        return;
      }
      setIsLoading(false);
    }

    effect();
  }, [history]);

  if (isLoading) {
    return null;
  }

  return <Route {...rest}>{children}</Route>;
};

export default PublicRoute;
