/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { Flag } from '../services/flags/types';

export enum State {
  User = 'users',
  Organization = 'organizations',
  Event = 'events',
  System = 'sys',
}

const initialState = {
  [State.User]: {},
  [State.Event]: {},
  [State.Organization]: {},
  [State.System]: {},
};

const prepare = (flags: Flag[]) => {
  let payload = null;

  if (flags) {
    payload = flags.reduce(
      (prev, { enabled: value, keyId: key }) => ({
        ...prev,
        [key]: value,
      }),
      {}
    );
  }

  return { payload, meta: null, error: null };
};

const slice = createSlice({
  name: 'flags',
  initialState,
  reducers: {
    setUser: {
      prepare,
      reducer(state, { payload }) {
        state[State.User] = payload || initialState[State.User];
      },
    },
    setOrganization: {
      prepare,
      reducer(state, { payload }) {
        state[State.Organization] = payload || initialState[State.Organization];
      },
    },
    setEvent: {
      prepare,
      reducer(state, { payload }) {
        state[State.Event] = payload || initialState[State.Event];
      },
    },
    setSystem: {
      prepare,
      reducer(state, { payload }) {
        state[State.System] = payload || initialState[State.System];
      },
    },
  },
});

export default slice.reducer;

export const { setUser, setOrganization, setEvent, setSystem } = slice.actions;

export const getUser = state => state[slice.name]?.[State.User];
export const getOrganization = state => state[slice.name]?.[State.Organization];
export const getEvent = state => state[slice.name]?.[State.Event];
export const getSystem = state => state[slice.name]?.[State.System];

export const isEnabled = (state, key) =>
  state[slice.name]?.[State.User]?.[key] ??
  state[slice.name]?.[State.Organization]?.[key] ??
  state[slice.name]?.[State.Event]?.[key] ??
  state[slice.name]?.[State.System]?.[key] ??
  false;

export const isDisabled = (state, key) => !isEnabled(state, key);
