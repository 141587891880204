import React from 'react';
import { ConfigProvider as AntConfigProvider } from 'antd';
import { t } from 'ttag';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';

interface Props {
  locale: string;
  children: React.ReactNode;
}

const AntDTranslationProvider = ({ locale, children }: Props) => {
  // source strings are taken from https://github.com/react-component/pagination/blob/master/src/locale/en_US.js
  const Pagination = {
    // Options.jsx
    items_per_page: t`/ page`,
    jump_to: t`Go to`,
    jump_to_confirm: t`confirm`,

    // Pagination.jsx
    prev_page: t`Previous Page`,
    next_page: t`Next Page`,
    prev_5: t`Previous 5 Pages`,
    next_5: t`Next 5 Pages`,
    prev_3: t`Previous 3 Pages`,
    next_3: t`Next 3 Pages`,
  };

  // source string are taken from https://github.com/ant-design/ant-design/blob/3.x-stable/components/time-picker/locale/en_US.tsx
  const TimePicker = {
    placeholder: t`Select time`,
  };

  // source strings are taken from https://github.com/ant-design/ant-design/blob/3.x-stable/components/date-picker/locale/en_US.tsx
  // + https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json
  // + https://github.com/react-component/calendar/blob/master/src/locale/en_US.js
  const DatePicker: PickerLocale = {
    lang: {
      locale,
      placeholder: t`Select date`,
      rangePlaceholder: [t`Start date`, t`End date`],
      yearPlaceholder: t`Select year`,
      quarterPlaceholder: t`Select quarter`,
      monthPlaceholder: t`Select month`,
      weekPlaceholder: t`Select week`,
      rangeYearPlaceholder: [t`Start year`, t`End year`],
      rangeMonthPlaceholder: [t`Start month`, t`End month`],
      rangeWeekPlaceholder: [t`Start week`, t`End week`],
      // taken from https://github.com/react-component/calendar/blob/master/src/locale/en_US.js
      today: t`Today`,
      now: t`Now`,
      backToToday: t`Back to today`,
      ok: t`Ok`,
      clear: t`Clear`,
      month: t`Month`,
      year: t`Year`,
      timeSelect: t`select time`,
      dateSelect: t`select date`,
      weekSelect: t`Choose a week`,
      monthSelect: t`Choose a month`,
      yearSelect: t`Choose a year`,
      decadeSelect: t`Choose a decade`,
      yearFormat: `YYYY`,
      dateFormat: `M/D/YYYY`,
      dayFormat: `D`,
      dateTimeFormat: `M/D/YYYY HH:mm:ss`,
      monthBeforeYear: true,
      previousMonth: t`Previous month (PageUp)`,
      nextMonth: t`Next month (PageDown)`,
      previousYear: t`Last year (Control + left)`,
      nextYear: t`Next year (Control + right)`,
      previousDecade: t`Last decade`,
      nextDecade: t`Next decade`,
      previousCentury: t`Last century`,
      nextCentury: t`Next century`,
    },
    timePickerLocale: {
      ...TimePicker,
    },
    dateFormat: 'YYYY-MM-DD',
    dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
    weekFormat: 'YYYY-wo',
    monthFormat: 'YYYY-MM',
  };

  return (
    <AntConfigProvider
      locale={{
        // source strings are taken from https://github.com/ant-design/ant-design/blob/3.x-stable/components/locale/default.tsx
        locale,
        global: {
          placeholder: t`Please select`,
        },

        Pagination,
        // Calendar strings are a copy of date-picker: https://github.com/ant-design/ant-design/blob/3.x-stable/components/calendar/locale/en_US.tsx
        Calendar: DatePicker,
        DatePicker,
        TimePicker,

        Table: {
          filterTitle: t`Filter menu`,
          filterConfirm: t`OK`,
          filterReset: t`Reset`,
          filterEmptyText: t`No filters`,
          emptyText: t`No data`,
          selectAll: t`Select current page`,
          selectInvert: t`Invert current page`,
          selectionAll: t`Select all data`,
          sortTitle: t`Sort`,
          expand: t`Expand row`,
          collapse: t`Collapse row`,
          triggerDesc: t`Click to sort by descending`,
          triggerAsc: t`Click to sort by ascending`,
          cancelSort: t`Click to cancel sorting`,
        },
        Modal: {
          okText: t`OK`,
          cancelText: t`Cancel`,
          justOkText: t`OK`,
        },
        Popconfirm: {
          okText: t`OK`,
          cancelText: t`Cancel`,
        },
        Transfer: {
          titles: [t`', '`],
          searchPlaceholder: t`Search here`,
          itemUnit: t`item`,
          itemsUnit: t`items`,
          remove: t`Remove`,
          selectCurrent: t`Select current page`,
          removeCurrent: t`Remove current page`,
          selectAll: t`Select all data`,
          removeAll: t`Remove all data`,
          selectInvert: t`Invert current page`,
        },
        Upload: {
          uploading: t`Uploading...`,
          removeFile: t`Remove file`,
          uploadError: t`Upload error`,
          previewFile: t`Preview file`,
          downloadFile: t`Download file`,
        },
        Empty: {
          description: t`No Data`,
        },
        Icon: {
          icon: t`icon`,
        },
        Text: {
          edit: t`Edit`,
          copy: t`Copy`,
          copied: t`Copied`,
          expand: t`Expand`,
        },
        PageHeader: {
          back: t`Back`,
        },
      }}
    >
      {children}
    </AntConfigProvider>
  );
};

export default AntDTranslationProvider;
