import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Form as AntForm, Space, Input, message, Popover, Typography } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  WarningFilled,
  CheckCircleFilled,
} from '@ant-design/icons';
import { t } from 'ttag';
import { useHistory } from 'react-router-dom';
import { PasswordProps } from 'antd/lib/input';

import useSearchParams from '../../../hooks/useSearchParams';
import useTranslation from '../../../hooks/useTranslation';
import Label from '../../Label';

import Layout from '../Layout';
import { ResetPasswordPayload } from '../../../services/auth/types';
import { validateToken, resetPassword } from '../../../services/auth/api';

const { Title: AntTitle, Text } = Typography;
const { useForm } = AntForm;

const ONE_LETTER_REGEX = /[a-zA-Z]/;
const ONE_NUMBER_REGEX = /[0-9]/;
const ONE_SPECIAL_CHARACTER_REGEX = /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;
const EIGHT_CHARACTERS_REGEX = /.{8}/;

const Title = styled(AntTitle)`
  && {
    align-self: center;
    margin-bottom: 1.5rem;
  }
`;

const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Field = styled(AntForm.Item)`
  && {
    margin-bottom: 24px;
    @media (max-width: 600px) {
      flex-direction: row;
    }
  }
`;

const Check = styled.div<{ isValid: boolean }>`
  color: ${({ isValid }) => (isValid ? '#6EBB83' : '#ff4d4f')};
  svg {
    margin-right: 8px;
  }
`;

const validatePassword = (password: string) => {
  const hasLetter = !!password.match(ONE_LETTER_REGEX);
  const hasNumber = !!password.match(ONE_NUMBER_REGEX);
  const hasSpecialCharacter = !!password.match(ONE_SPECIAL_CHARACTER_REGEX);
  const hasEightCharacters = !!password.match(EIGHT_CHARACTERS_REGEX);
  const isValid = password && hasLetter && hasNumber && hasSpecialCharacter && hasEightCharacters;

  return { hasLetter, hasNumber, hasSpecialCharacter, hasEightCharacters, isValid };
};

interface PasswordCheckProps {
  children: React.ReactNode;
  isValid: boolean;
}

const PasswordCheck = ({ children, isValid }: PasswordCheckProps) => (
  <Check isValid={isValid}>
    {isValid && <CheckCircleFilled />}
    {!isValid && <WarningFilled />}
    {children}
  </Check>
);

const PasswordInstructions = ({ password }: { password: string }) => {
  const { hasEightCharacters, hasLetter, hasNumber, hasSpecialCharacter } = validatePassword(
    password
  );

  return (
    <div data-testid="passwordInstructions">
      <PasswordCheck isValid={hasEightCharacters}>{t`Minimum 8 characters`}</PasswordCheck>
      <PasswordCheck isValid={hasLetter}>{t`Contains 1 letter`}</PasswordCheck>
      <PasswordCheck isValid={hasNumber}>{t`Contains 1 number`}</PasswordCheck>
      <PasswordCheck isValid={hasSpecialCharacter}>{t`Contains 1 special character`}</PasswordCheck>
    </div>
  );
};

interface PasswordFieldProps extends PasswordProps {
  showInstructions: boolean;
}

const PasswordField = ({ value, onChange, showInstructions, ...rest }: PasswordFieldProps) => (
  <Popover
    open={showInstructions}
    placement="bottomLeft"
    content={<PasswordInstructions password={value as string} />}
  >
    <Input.Password value={value} data-testid="password" onChange={onChange} {...rest} />
  </Popover>
);

const useValidateToken = (
  token: string,
  validateTokenFn: (token: string) => ReturnType<typeof validateToken>
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const effect = async () => {
      setLoading(true);

      try {
        await validateTokenFn(token);
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      effect();
    } else {
      setError(true);
    }
  }, [token, validateTokenFn]);

  return {
    loading,
    error,
  };
};

const visibilityIcon = (visible: boolean) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />);

export interface Props {
  validateTokenFn: (token: string) => ReturnType<typeof validateToken>;
  resetPasswordFn: (
    payload: Pick<ResetPasswordPayload, 'token' | 'password'>
  ) => ReturnType<typeof resetPassword>;
  afterReset?: () => void;
  showReturnToLogin?: boolean;
}

const ResetPassword = ({
  validateTokenFn,
  resetPasswordFn,
  afterReset = null,
  showReturnToLogin = false,
}: Props) => {
  const [form] = useForm();
  const { validateFields, getFieldValue } = form;
  const [loading, setLoading] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const history = useHistory();
  const query = useSearchParams();
  const token = query?.get('token');

  useTranslation();

  const { error, loading: validatingToken } = useValidateToken(token, validateTokenFn);

  const handleOnFocus = () => {
    setShowPasswordPopup(true);
    setInputFocus(true);
  };

  const handleOnBlur = () => {
    setShowPasswordPopup(false);
    setInputFocus(false);
  };

  const onFinish = async () => {
    setLoading(true);
    const values = await validateFields();
    const payload = {
      password: values.password,
      token,
    };

    try {
      await resetPasswordFn(payload);
      message.success(t`Your password has been updated successfully.`);
      if (afterReset) {
        afterReset();
      }
    } catch {
      // TODO: handle API error handling when integrating with CP or EXP
      message.error(t`Unexpected error while setting a new password.`);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <Layout>
        <Space direction="vertical" size="middle">
          <Text>{t`Oops! Something went wrong. Please check your email or try resetting your password again.`}</Text>

          <Button
            style={{ width: '100%' }}
            onClick={window.location.reload}
          >{t`Reset Password`}</Button>
          {showReturnToLogin && (
            <Button
              style={{ width: '100%' }}
              type="primary"
              onClick={() => history.replace('/')}
            >{t`Return to Login`}</Button>
          )}
        </Space>
      </Layout>
    );
  }

  return (
    <Layout>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        hideRequiredMark
        onFinishFailed={() => message.error(t`Please address the errors shown below`)}
        initialValues={{ password: '', confirmPassword: '' }}
      >
        <>
          <Title level={4}>{t`Set a New Password`}</Title>
          <Field
            label={<Label isRequired>{t`Password`}</Label>}
            name="password"
            validateTrigger={['onChange', 'onFocus', 'onBlur']}
            rules={[
              { required: true, message: t`Password is required.` },
              {
                validator: (_, value) => {
                  const { isValid } = validatePassword(value);

                  if (isValid) {
                    setShowPasswordPopup(false);
                    return Promise.resolve();
                  }
                  if (inputFocus) {
                    setShowPasswordPopup(true);
                  }
                  return Promise.reject(new Error(''));
                },
              },
            ]}
          >
            <PasswordField
              showInstructions={showPasswordPopup}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          </Field>
          <Field
            label={<Label isRequired>{t`Confirm New Password`}</Label>}
            name="confirmPassword"
            rules={[
              { required: true, message: t`Password is required.` },
              {
                validator: (_, value) => {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t`Passwords must match.`));
                },
              },
            ]}
          >
            <Input.Password data-testid="confirmPassword" iconRender={visibilityIcon} />
          </Field>
          <Field shouldUpdate noStyle>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={
                  loading ||
                  !(getFieldValue('password') && getFieldValue('confirmPassword')) ||
                  validatingToken
                }
                style={{ marginBottom: '16px' }}
              >
                {loading ? t`Setting New Password...` : t`Set New Password`}
              </Button>
            )}
          </Field>
          {showReturnToLogin && (
            <Button onClick={() => history.replace('/')}>{t`Return to Login`}</Button>
          )}
        </>
      </Form>
    </Layout>
  );
};

export default ResetPassword;
