/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { CompanyEvent } from '~services/companyEvents/type';

const initialState: CompanyEvent = {
  id: null,
  externalId: null,
  name: null,
  event: null,
  leadCapture: {
    enabled: false,
  },
};

const slice = createSlice({
  name: 'companyEvent',
  initialState,
  reducers: {
    set(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    reset() {
      return initialState;
    },
  },
});

export default slice.reducer;

export const get = (state): CompanyEvent => state[slice.name];
export const getCompany = state => {
  const { id, externalId, name } = state[slice.name];
  return { id, externalId, name };
};
export const isLeadCaptureEnabled = ({ companyEvent }: { companyEvent: CompanyEvent }) =>
  companyEvent.leadCapture?.enabled || false;

export const getEvent = state => state[slice.name].event;

export const { set, reset } = slice.actions;
