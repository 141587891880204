import { QueryFunction, QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

export interface Query<
  TQueryKey extends QueryKey = QueryKey,
  TKeyArgs extends any[] = any[],
  TQueryData = unknown
> {
  getQueryKey: (...args: TKeyArgs) => TQueryKey;
  queryFn: QueryFunction<TQueryData, TQueryKey>;
}

export const createQueryDefinition = <
  TQueryKey extends QueryKey,
  TKeyArgs extends any[],
  TQueryData
>(
  getQueryKey: (...args: TKeyArgs) => TQueryKey,
  queryFn: QueryFunction<TQueryData, TQueryKey>
): Query<TQueryKey, TKeyArgs, TQueryData> => ({
  getQueryKey,
  queryFn,
});

export const createQueryHook = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TQueryParams extends any[] = any[]
>(
  { getQueryKey, queryFn }: Query<TQueryKey, TQueryParams, TQueryFnData>,
  hookOptions?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  const useNamedQuery = (
    params: TQueryParams,
    options?: Omit<
      UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
      'queryKey' | 'queryFn' | 'select'
    >
  ) => {
    type TMergedOptions = UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>;
    return useQuery({
      queryKey: getQueryKey(...params),
      queryFn,
      ...(hookOptions || {}),
      ...(options || {}),
    } as TMergedOptions);
  };
  return useNamedQuery;
};
