import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
`;

const Loading = () => (
  <Spinner>
    <Spin />
  </Spinner>
);

export default Loading;
