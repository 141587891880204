import React, { ReactNode } from 'react';
import { Space } from 'antd';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
  fullWidth?: boolean;
  paddingLeft?: string;
};

type WrapProps = {
  $fullWidth: boolean;
  $paddingLeft: string;
};

const Wrap = styled.div<WrapProps>`
  position: fixed;
  bottom: 0;
  right: 0;
  left: ${props => (props.$fullWidth ? '0' : '230px')};
  z-index: 100;
  width: 100%;
  height: 52px;
  padding: 10px ${props => (props.$fullWidth ? '45px' : props.$paddingLeft)};
  background-color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid #e8e8e8;
`;

const StickyFooter = ({ children, fullWidth = false, paddingLeft = '58px' }: Props) => (
  <Wrap $fullWidth={fullWidth} $paddingLeft={paddingLeft}>
    <Space>{children}</Space>
  </Wrap>
);

export default StickyFooter;
