import React, { useEffect, useState } from 'react';
import { Form, Input, Select as AntSelect, Button, message } from 'antd';
import styled from 'styled-components';
import { t, jt } from 'ttag';
import { useHistory } from 'react-router-dom';

import { SUPPORT_EMAIL } from '../../services/support/types';

import { ISO_LOCALE_MAP, LANGUAGE_BY_ISO } from '../../services/translation/api';
import * as AuthService from '../../services/auth/api';
import { UpdatePayload } from '../../services/auth/types';
import Emphasis from '../Emphasis';
import Label from '../Label';
import StickyFooter from '../StickyFooter';
import Loading from '../Loading';

const { useForm } = Form;

const Wrap = styled.div`
  margin: 0 0 40px 45px;
  padding: 20px 0;
`;

const Select = styled(AntSelect)`
  width: 100%;
  .ant-select-selection-item {
    height: 26px;
  }
  .ant-select-selection-search {
    margin-left: 0;
  }
`;

const Field = styled(Form.Item)`
  && {
    max-width: 716px;
    margin-bottom: 15px;
  }
`;

export interface Props {
  getFn: typeof AuthService.get;
  updateFn: (payload: UpdatePayload) => Promise<void>;
}

const Presentation = ({ getFn, updateFn }: Props) => {
  const history = useHistory();
  const [form] = useForm<UpdatePayload>();
  const { validateFields } = form;
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function effect() {
      try {
        setIsLoading(true);
        const { data } = await getFn();
        setUser(data);
        setIsLoading(false);
      } catch {
        message.error(t`Error loading profile.`);
      }
    }

    effect();
  }, [form, getFn]);

  const onFinish = async () => {
    try {
      setIsUpdating(true);
      const { lastName, firstName, language: newLanguage, email } = await validateFields();
      await updateFn({
        lastName,
        firstName,
        language: newLanguage,
        email,
      });

      message.success(t`Profile saved successfully.`);
    } catch (e) {
      message.error(t`Error saving profile.`);
    } finally {
      setIsUpdating(false);
    }
  };

  const supportEmailLink = (
    <a
      key="supportEmail"
      href={`mailto:${SUPPORT_EMAIL}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {SUPPORT_EMAIL}
    </a>
  );

  return (
    <Wrap>
      {isLoading && <Loading />}

      {!isLoading && (
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          initialValues={user}
          onFinish={onFinish}
        >
          <Field
            label={<Label isRequired>{t`First Name`}</Label>}
            name="firstName"
            rules={[{ required: true, message: t`First Name is required` }]}
          >
            <Input />
          </Field>
          <Field
            label={<Label isRequired>{t`Last Name`}</Label>}
            name="lastName"
            rules={[{ required: true, message: t`Last Name is required` }]}
          >
            <Input />
          </Field>
          <Field label={<Label isRequired>{t`Email`}</Label>}>
            <Field
              noStyle
              name="email"
              rules={[{ type: 'email', message: t`Invalid email address.` }]}
            >
              <Input disabled />
            </Field>
            <Emphasis>{jt`To change your email address, please contact ${supportEmailLink}`}</Emphasis>
          </Field>
          <Field
            name="language"
            label={<Label isRequired>{t`Experience Manager Language`}</Label>}
            rules={[{ required: true, message: t`Language is required` }]}
          >
            <Select
              placeholder={t`Select Language`}
              data-testid="select-language"
              options={Object.keys(ISO_LOCALE_MAP).map(code => ({
                value: code,
                label: LANGUAGE_BY_ISO(code),
              }))}
            />
          </Field>
          <StickyFooter fullWidth>
            <Button
              type="primary"
              htmlType="submit"
              loading={isUpdating}
              disabled={isUpdating || isLoading}
            >
              {isUpdating ? t`Updating...` : t`Save Changes`}
            </Button>
            <Button type="default" disabled={isUpdating} onClick={() => history.goBack()}>
              {t`Cancel`}
            </Button>
          </StickyFooter>
        </Form>
      )}
    </Wrap>
  );
};

export default Presentation;
