import React, { useCallback, useState } from 'react';
import { Button, Dropdown, MenuProps, Space, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { t } from 'ttag';
import {
  CommentOutlined,
  LinkOutlined,
  MailOutlined,
  NotificationOutlined,
  PlaySquareOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import styled, { createGlobalStyle } from 'styled-components';
import moment from 'moment';

import { getUser } from '../state/user';

import { open as openHubSpot } from '../services/support';

import { SUPPORT_EMAIL, SupportPhoneUrl, SupportPhone } from '../services/support/types';
import useTranslation from '../hooks/useTranslation';
import { Category } from '../services/appcues/api';
import { AppcuesLaunchpadFlow } from '../services/appcues/types';
import Portal from '../types/Portal';

import { useLaunchpad } from './Appcues';

enum KnowledgebaseUrl {
  EN = 'https://help.eventmobi.com/en/knowledge',
  DE = 'https://help.eventmobi.com/de/knowledge',
}

const PartialTitle = styled(Typography.Text).attrs({ strong: true })`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

// global styles for Announcements and Walkthroughs submenus in the Dropdown component to control the sizing and scrolling behavior
const GlobalStyles = createGlobalStyle`
   .help-dropdown-popout-menu ul {
    max-height: 500px;
    overflow: auto;
  }
`;

const HelpDropdown = () => {
  const { loading: loadingLaunchpad, appcues, content: launchpadContent } = useLaunchpad();
  const { locale } = useTranslation();
  const user = useSelector(getUser);
  const [hubspotLoaded, setHubspotLoaded] = useState(false);

  const announcements = launchpadContent
    .filter(item => item.category === Category.ANNOUNCEMENTS)
    // recently published announcement should come first
    .sort((a, b) => b.createdAt - a.createdAt);
  const walkthroughs = launchpadContent
    .filter(item => item.category === Category.WALKTHROUGHS)
    // recently updated walkthough should come first
    .sort((a, b) => b.updatedAt - a.updatedAt);

  const onClickLaunchpadFlow = (item: AppcuesLaunchpadFlow) => appcues.show(item.id);

  const knowledgebaseUrl = /de/.test(locale) ? KnowledgebaseUrl.DE : KnowledgebaseUrl.EN;

  const supportPhoneUrl = /de/.test(locale) ? SupportPhoneUrl.DE : SupportPhoneUrl.EN;
  const supportPhone = /de/.test(locale) ? SupportPhone.DE : SupportPhone.EN;

  const checkHubspot = useCallback(() => {
    const loaded = !!document.getElementById('hs-script-loader');
    setHubspotLoaded(loaded);
  }, []);

  const openChat = () => {
    openHubSpot({ email: user.email }, Portal.CompanyPortal);
  };

  function getItemsWithDivider(items, getLabel) {
    return items
      .reduce((accum, item) => {
        accum.push({
          key: item.id,
          onClick: () => onClickLaunchpadFlow(item),
          label: getLabel(item),
        });
        accum.push({
          type: 'divider',
          style: { marginTop: 0, marginBottom: 0 },
        });

        return accum;
      }, [])
      .slice(0, -1);
  }

  const items: MenuProps['items'] = [
    {
      key: 'announcements',
      label: t`Announcements`,
      disabled: loadingLaunchpad || announcements.length === 0,
      icon: <NotificationOutlined />,
      popupClassName: 'help-dropdown-popout-menu',
      children: getItemsWithDivider(announcements, item => (
        <Space direction="vertical" style={{ width: 256 }}>
          <Typography.Text style={{ fontSize: 12 }}>
            {moment(item.createdAt).format('DD-MMM-YYYY')}
          </Typography.Text>
          <PartialTitle>{item.name}</PartialTitle>
        </Space>
      )),
    },
    {
      key: 'walkthroughs',
      label: t`Walkthroughs`,
      disabled: loadingLaunchpad || walkthroughs.length === 0,
      icon: <PlaySquareOutlined />,
      popupClassName: 'help-dropdown-popout-menu',
      children: getItemsWithDivider(walkthroughs, item => (
        <Space direction="vertical" style={{ width: 256 }}>
          <PartialTitle>{item.name}</PartialTitle>
        </Space>
      )),
    },
    {
      key: 'knowledge-base',
      icon: <LinkOutlined />,
      label: (
        <a href={knowledgebaseUrl} target="_blank" rel="noopener noreferrer">
          {t`Visit our Knowledge Base`}
        </a>
      ),
    },
    { key: 'divider-1', type: 'divider', style: { marginTop: 0, marginBottom: 0 } },
    {
      key: 'contact-us',
      label: t`Contact Us`,
      type: 'group',
    },
    hubspotLoaded && {
      key: 'chat',
      onClick: openChat,
      icon: <CommentOutlined />,
      label: t`Live Chat`,
    },
    {
      key: 'call-us',
      icon: <PhoneOutlined />,
      label: <a href={supportPhoneUrl}>{t`Call us ${supportPhone}`}</a>,
    },
    {
      key: 'email',
      icon: <MailOutlined />,
      label: <a href={`mailto:${SUPPORT_EMAIL}`}>{t`Email ${SUPPORT_EMAIL}`}</a>,
    },
  ].filter(Boolean);

  return (
    <>
      <Dropdown trigger={['click']} onOpenChange={checkHubspot} menu={{ items }}>
        <Button style={{ color: '#1890FF', borderColor: '#1890FF' }}>
          <QuestionCircleOutlined />
          {t`Help`}
        </Button>
      </Dropdown>
      <GlobalStyles />
    </>
  );
};

export default HelpDropdown;
