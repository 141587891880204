import React from 'react';
import { Route } from 'react-router-dom';

import PublicRoute from 'common/components/Auth/PublicRoute';
import RoutePath from '~types/RoutePath';

import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import AcceptInvite from './AcceptInvite';
import ActivateAccount from './ActivateAccount';

const AuthRoutes = [
  <PublicRoute key={RoutePath.Login} path={RoutePath.Login} exact>
    <Login />
  </PublicRoute>,
  <PublicRoute key={RoutePath.ForgotPassword} path={RoutePath.ForgotPassword} exact>
    <ForgotPassword />
  </PublicRoute>,
  <PublicRoute key={RoutePath.ResetPassword} path={RoutePath.ResetPassword} exact>
    <ResetPassword />
  </PublicRoute>,
  <Route key={RoutePath.AcceptInvite} path={RoutePath.AcceptInvite}>
    <AcceptInvite />
  </Route>,
  <PublicRoute key={RoutePath.ActivateAccount} path={RoutePath.ActivateAccount} exact>
    <ActivateAccount />
  </PublicRoute>,
];

export default AuthRoutes;
