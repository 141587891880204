import React from 'react';
import { Menu as AntMenu } from 'antd';
import {
  Link,
  useParams,
  generatePath,
  Route,
  useLocation,
  matchPath,
  Redirect,
  Switch,
} from 'react-router-dom';
import { t } from 'ttag';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck';
import { faPenAlt } from '@fortawesome/free-solid-svg-icons/faPenAlt';
import { faServer } from '@fortawesome/free-solid-svg-icons/faServer';
import styled, { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import { isLeadCaptureEnabled } from '~state/companyEvent';
import useConfig from 'common/hooks/useConfig';
import FeatureFlags from 'common/types/FeatureFlags';
import HTTP404 from 'common/components/ErrorRoutes/HTTP404';

import RoutePath from '~types/RoutePath';

const Details = React.lazy(() =>
  import(/* webpackChunkName: "companyDetails" */ '~domains/Company/Details')
);
const Representatives = React.lazy(() =>
  import(/* webpackChunkName: "companyRepresentatives" */ '~domains/Company/Representatives')
);
const LeadQualifier = React.lazy(() =>
  import(/* webpackChunkName: "leadQualifier" */ '~domains/LeadQualifier')
);

const LeadCollection = React.lazy(() =>
  import(/* webpackChunkName: "leadCollection" */ '~domains/LeadCollection')
);

const StyleOverrides = createGlobalStyle`
  .ant-menu-item-group-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .ant-menu-item-divider {
    && {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }
  `;
const Content = styled.div`
  overflow: auto;
  width: 100%;
`;
const Menu = styled(AntMenu)`
  height: 100%;
  overflow: auto;
  width: 230px;
  flex-shrink: 0;
  z-index: 999;
`;

const FontAwesomeIcon = styled(Icon)`
  width: 14px !important;
`;

const getSelectedMenuKeys = url => {
  const currentRouteKeys: string[] = [];

  Object.entries(RoutePath).forEach(([key, route]) => {
    if (matchPath(url, { path: route, exact: true })) {
      currentRouteKeys.push(key);
    }
  });

  return currentRouteKeys;
};

interface Item {
  label?: string;
  key?: RoutePath;
  link?: string;
  type?: 'divider' | 'group';
  icon?: React.ReactNode;
  children?: ItemType[];
}

const getItem = ({ label, key, link, type, icon, children }: Item): ItemType => ({
  key: key ? Object.entries(RoutePath).find(([, route]) => route === key)[0] : label,
  label: link ? <Link to={link}>{label}</Link> : label,
  type,
  icon,
  children,
});

interface SideNavigationProps {
  showLeadCaptureMenu: boolean;
}

export const SideNavigation = ({ showLeadCaptureMenu }: SideNavigationProps) => {
  const { eventId, companyId } = useParams<{
    eventId: string;
    companyId: string;
  }>();
  const { pathname } = useLocation();
  const generateUrl = (path: string) => generatePath(path, { eventId, companyId });

  const items = [
    getItem({
      label: t`Company Details`,
      key: RoutePath.CompanyDetail,
      link: generateUrl(RoutePath.CompanyDetail),
      icon: <FontAwesomeIcon icon={faInfoCircle} />,
    }),
    getItem({
      label: t`Representatives`,
      key: RoutePath.CompanyRepresentatives,
      link: generateUrl(RoutePath.CompanyRepresentatives),
      icon: <FontAwesomeIcon icon={faUserFriends} />,
    }),
  ];

  if (showLeadCaptureMenu) {
    items.push(
      getItem({
        type: 'divider',
      }),
      getItem({
        label: t`Features`,
        type: 'group',
        children: [
          getItem({
            label: t`Lead Capture`,
            type: 'divider',
            icon: <FontAwesomeIcon icon={faClipboardCheck} />,
            children: [
              getItem({
                label: t`Lead Qualifier`,
                key: RoutePath.LeadQualifier,
                link: generateUrl(RoutePath.LeadQualifier),
                icon: <FontAwesomeIcon icon={faPenAlt} />,
              }),
              getItem({
                label: t`Lead Collection`,
                key: RoutePath.LeadCollection,
                link: generateUrl(RoutePath.LeadCollection),
                icon: <FontAwesomeIcon icon={faServer} />,
              }),
            ],
          }),
        ],
      })
    );
  }

  return (
    <>
      <StyleOverrides key="overrides" />
      <Menu
        key="left-nav"
        mode="inline"
        selectedKeys={getSelectedMenuKeys(pathname)}
        theme="dark"
        items={items}
      />
    </>
  );
};

const SideNavigationRoutes = () => {
  const { isEnabled, loading } = useConfig();
  const isLeadCaptureFlagEnabled = isEnabled(FeatureFlags.ConfLeadCapture);
  const leadCaptureEnabled = useSelector(isLeadCaptureEnabled);

  return (
    <>
      <SideNavigation showLeadCaptureMenu={isLeadCaptureFlagEnabled && leadCaptureEnabled} />
      <Content>
        <Switch>
          <Route path={RoutePath.Company} exact>
            <Redirect to={RoutePath.CompanyDetail} />
          </Route>
          <Route path={RoutePath.CompanyDetail} exact>
            <Details />
          </Route>
          {/* Please note the Route gives strange behaviour when it is wrapped with empty tags */}
          {isLeadCaptureFlagEnabled && leadCaptureEnabled && (
            <Route path={RoutePath.LeadQualifier} exact>
              <LeadQualifier />
            </Route>
          )}
          {isLeadCaptureFlagEnabled && leadCaptureEnabled && (
            <Route path={RoutePath.LeadCollection} exact>
              <LeadCollection />
            </Route>
          )}
          <Route path={RoutePath.CompanyRepresentatives} exact>
            <Representatives />
          </Route>
          {!loading && (
            <Route path="*">
              <HTTP404 />
            </Route>
          )}
        </Switch>
      </Content>
    </>
  );
};

export default SideNavigationRoutes;
