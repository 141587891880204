import React from 'react';
import { t } from 'ttag';

import AuthLogin from 'common/components/Auth/Login';
import useTranslation from 'common/hooks/useTranslation';

import RoutePath from '~types/RoutePath';
import { login as loginFn } from './service';

const Login = () => {
  useTranslation();
  return (
    <AuthLogin
      title={t`Company Portal Log In`}
      redirectUrl={RoutePath.CompanyEvent}
      loginFn={loginFn}
    />
  );
};

export default Login;
