import { useQuery } from '@tanstack/react-query';
import { createQueryDefinition } from '../queryBuilder';
import { get as getFn } from './api';

export const GetCompanyQuery = createQueryDefinition(
  (eventId: string, companyId: string) => [eventId, 'companies', companyId] as const,
  ({ queryKey: [eventId, , companyId] }) =>
    getFn(eventId, companyId, [
      'groups',
      'location',
      'linkedSessions',
      'representatives',
      'video',
      'callToAction',
      'externalLinks',
      'documents',
      'leadCapture',
      'videoContent',
    ])
);

export const useCompanyQuery = (eventId: string, companyId: string) =>
  useQuery({
    queryKey: GetCompanyQuery.getQueryKey(eventId, companyId),
    queryFn: GetCompanyQuery.queryFn,
    enabled: !!eventId,
  });
