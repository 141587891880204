import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { t } from 'ttag';

import useSearchParams from 'common/hooks/useSearchParams';
import AcceptInvite from 'common/components/Auth/AcceptInvite';
import {
  acceptAdminInvite as acceptAdminInviteFn,
  checkAdminInviteStatus as checkAdminInviteStatusFn,
  isAuthenticated as isAuthenticatedFn,
} from 'common/services/auth/api';

import RoutePath from '~types/RoutePath';
import { AcceptInvitePayload } from 'common/services/auth/types';

export default () => {
  const query = useSearchParams();
  const history = useHistory();
  const token = query.get('token');
  const companyId = query.get('company_id');
  const companyName = query.get('company_name');
  const location = useLocation();

  const handleAcceptInvite = async () => {
    const isAuthenticated = await isAuthenticatedFn();
    if (isAuthenticated) {
      history.replace(RoutePath.CompanyEvent);
      return;
    }
    const queryParams = new URLSearchParams(location.search).toString();
    history.replace(`${RoutePath.ActivateAccount}?${queryParams}`);
  };

  return (
    <AcceptInvite
      token={token}
      id={companyId}
      description={t`Accept the invitation to gain access to ${companyName}’s Company Portal as a Company Admin.`}
      checkInviteStatusFn={async () => {
        const { data } = await checkAdminInviteStatusFn({ token, companyId });
        return data;
      }}
      acceptInviteFn={async (payload: AcceptInvitePayload) => {
        const { data } = await acceptAdminInviteFn(
          payload ? { ...payload, token, companyId } : { token, companyId }
        );
        return data;
      }}
      onAccept={handleAcceptInvite}
      onLogin={() => history.replace(RoutePath.Login)}
    />
  );
};
