import apiFn from 'common/services/api-service';
import { LoginPayload, User } from 'common/services/auth/types';
import { store } from 'common/services/session-service';

export const AUTH_APP = 'company_portal';

export const login = async (payload: LoginPayload): Promise<User> => {
  const user = await apiFn(`/api/uapi/company-portal/login`)
    .post('', payload)
    .then(response => response.data.data);

  const session = await apiFn('/api/uapi/identity/me')
    .get('')
    .then(response => response.data.data);

  const data = { ...session, ...user };
  await store(data);
  return data;
};

export default {};
