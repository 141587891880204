import { useContext } from 'react';

import TranslationContext from '../components/Translation/context';

/**
 * Subscribes a component to language changes, and allows components to update
 * the language where necessary.
 *
 * Most components in the tree should automatically update when the language is
 * changed. For the few that don't, just `useTranslation()` to subscribe them
 * explicitly.
 * @returns {object} `{ locale, language, setLanguage }`
 */
const useTranslation = () => useContext(TranslationContext);

export default useTranslation;
