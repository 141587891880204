import React from 'react';
import styled from 'styled-components';
import { Tabs, Typography, Space } from 'antd';
import { t } from 'ttag';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';

import useTranslation from 'common/hooks/useTranslation';

import Profile from './Profile';

enum Tab {
  UserProfile,
}

const TabLabel = () => ({
  [Tab.UserProfile]: t`Profile`,
});

const { Title: AntTitle } = Typography;

const Title = styled(AntTitle)`
  margin-left: 45px;
  margin-top: 40px;
  padding-bottom: 40px;
  && {
    font-weight: 400;
  },
  color: #595959,
`;

const Settings = () => {
  useTranslation();
  const history = useHistory();
  const { params, path } = useRouteMatch<{ tab: string }>();

  return (
    <Space direction="vertical" size="small">
      <Title>{t`Settings`}</Title>
      <Tabs
        type="card"
        activeKey={params.tab}
        style={{ overflow: 'visible' }}
        tabBarStyle={{ marginBottom: 0, paddingLeft: 45 }}
        onChange={tab => {
          history.push(generatePath(path, { ...params, tab }));
        }}
        items={[
          {
            key: Tab.UserProfile.toString(),
            label: TabLabel()[Tab.UserProfile],
            children: <Profile />,
          },
        ]}
      />
    </Space>
  );
};

export default Settings;
