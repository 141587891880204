import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider as AntDivider, Typography, Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { t } from 'ttag';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import Layout from 'common/components/Layout';
import { getUser, setUser } from 'common/state/user';
import { clean } from 'common/services/session-service';
import { logout } from 'common/services/auth/api';
import Portal from 'common/types/Portal';

import RoutePath from '~types/RoutePath';
import { get as getCompanyEvent, reset as resetCompanyEvent } from '~state/companyEvent';

interface Props {
  children: React.ReactNode;
}

const Divider = styled(AntDivider)`
  background-color: black;
  height: 22px;
`;

const UserSettingMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = async () => {
    await logout(Portal.CompanyPortal);
    dispatch(setUser(null));
    await clean();
    history.replace(RoutePath.Login);
  };

  return [
    {
      key: 'settings',
      label: (
        <Button
          style={{ padding: 0 }}
          type="text"
          onClick={() => history.push(RoutePath.ProfileSettings)}
        >
          <Typography.Text>{t`Settings`}</Typography.Text>
        </Button>
      ),
    },
    {
      key: 'logout',
      label: (
        <Button style={{ padding: 0 }} type="text" onClick={onLogout}>
          <LogoutOutlined />
          <Typography.Text>{t`Log out`}</Typography.Text>
        </Button>
      ),
    },
  ];
};

const selector = createSelector(getUser, getCompanyEvent, (user, companyEvent) => ({
  user,
  companyEvent,
}));

const PrivateLayout = ({ children }: Props) => {
  const { user, companyEvent } = useSelector(selector);
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <Layout
      userName={`${user.firstName} ${user.lastName}`}
      EntityMenu={<span style={{ cursor: 'pointer', fontWeight: 400 }}>{t`Company Portal`}</span>}
      navigateToHome={() => {
        history.push(RoutePath.CompanyEvent);
        dispatch(resetCompanyEvent());
      }}
      UserSettingMenu={UserSettingMenu()}
      selectedEntity={
        companyEvent.id && (
          <>
            {companyEvent.name}
            <Divider type="vertical" />
            {companyEvent.event?.name}
          </>
        )
      }
    >
      {children}
    </Layout>
  );
};

export default PrivateLayout;
