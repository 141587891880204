/* eslint-disable no-underscore-dangle */
import Portal from '../../types/Portal';
import { getVisitorToken } from './api';

// eslint-disable-next-line import/prefer-default-export
export const open = async (
  payload: { email: string } | { firstName: string; lastName: string; email: string },
  portalType: Portal
) => {
  const { loaded } = window.HubSpotConversations.widget.status();
  if (!loaded) {
    window._hsq = window._hsq ?? [];
    window._hsq.push(['doNotTrack']);
    const token = await getVisitorToken(payload, portalType);
    window.hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: payload.email,
      identificationToken: token,
    };
    window.HubSpotConversations.widget.load();
  }
  window.HubSpotConversations.widget.open();
};
