import React from 'react';
import styled from 'styled-components';
import {
  Breadcrumb as AntBreadcrumb,
  Button,
  Dropdown,
  Layout as AntLayout,
  PageHeader as AntPageHeader,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

import SeparatorImage from '../assets/breadcrumb-separator.svg';
import LogoImage from '../assets/EM-logo-colour-dark.svg';
import HelpDropdown from './HelpDropdown';

const { Header: AntHeader, Content: AntContent } = AntLayout;

const PageLayout = styled(AntLayout)`
  height: 100%;
`;

const PageHeader = styled(AntPageHeader)`
  padding: 0 5px;
  .ant-page-header-heading {
    height: 48px;
    align-items: center;
  }
  .ant-page-header-content {
    padding-top: 0;
  }
`;

const Header = styled(AntHeader)`
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  height: auto;
  padding: 0;
  width: 100%;
`;

const ContentLayout = styled(AntContent)`
  flex-shrink: 1;
`;

const Content = styled(AntContent)`
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

const Logo = styled.img`
  float: left;
  width: 108px;
  height: 48px;
`;

const Breadcrumb = styled(AntBreadcrumb)`
  li {
    display: flex;
    align-items: center;
  }
`;

export enum MenuItemType {
  divider = 'divider',
}

type Props = {
  children: React.ReactNode;
  userName: string;
  EntityMenu: JSX.Element;
  selectedEntity?: string | JSX.Element;
  UserSettingMenu?: (
    | {
        key: string | number;
        label: JSX.Element | string;
      }
    | { key: string | number; type: MenuItemType }
  )[];
  navigateToHome?: () => void;
};

const Layout = ({
  children,
  userName,
  UserSettingMenu = null,
  EntityMenu,
  selectedEntity = '',
  navigateToHome = null,
}: Props) => (
  <PageLayout>
    <Header>
      <PageHeader
        extra={
          <div>
            <HelpDropdown />
            <Dropdown trigger={['click']} menu={{ items: UserSettingMenu }}>
              <Button type="text">
                {userName} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        }
        title={
          <Breadcrumb separator={<img src={SeparatorImage} alt="separator" />}>
            <Breadcrumb.Item onClick={navigateToHome}>
              <Logo style={{ cursor: navigateToHome ? 'pointer' : 'default' }} src={LogoImage} />
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={navigateToHome}>{EntityMenu}</Breadcrumb.Item>
            {selectedEntity && <Breadcrumb.Item>{selectedEntity}</Breadcrumb.Item>}
          </Breadcrumb>
        }
      />
    </Header>
    <ContentLayout>
      <Content>{children}</Content>
    </ContentLayout>
  </PageLayout>
);

export default Layout;
