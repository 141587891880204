import React, { useEffect, useState } from 'react';
import { Space, Button, Typography, message } from 'antd';
import { t } from 'ttag';
import * as Sentry from '@sentry/react';

import Layout from './Layout';
import useInvite, { ERROR_MESSAGES } from './useInvite';
import { AcceptInvitePayload, AdminInviteStatus, User } from '../../services/auth/types';

const { Title, Paragraph } = Typography;

interface Props {
  id: string;
  token: string;
  description: string;
  checkInviteStatusFn: () => Promise<AdminInviteStatus>;
  acceptInviteFn: (payload?: AcceptInvitePayload) => Promise<User | null>;
  onLogin: () => void;
  onAccept: () => void;
}

const AcceptInvite = ({
  id,
  token,
  description,
  checkInviteStatusFn,
  acceptInviteFn,
  onLogin,
  onAccept,
}: Props) => {
  const [isAccepted, setIsAccepted] = useState(false);
  const { isValidating, status, accept, isAcceptInProgress } = useInvite(
    token,
    id,
    checkInviteStatusFn,
    acceptInviteFn
  );

  useEffect(() => {
    if (status?.isInvitationAccepted) {
      onLogin();
    }
  }, [status, onLogin]);

  const handleAcceptInvite = async () => {
    try {
      if (!status.isNewUser) {
        await accept();
      }
      message.success(t`Successfully accepted invite`);
      setIsAccepted(true);
      onAccept?.();
    } catch (err) {
      message.error(ERROR_MESSAGES().somethingWentWrong);
      Sentry.captureException(err);
    }
  };

  if (isValidating || !status) {
    return null;
  }

  if (!status.isEmailTokenValid || status.isInvitationAccepted === null) {
    return (
      <Layout>
        <Space direction="vertical" align="center" size="middle" style={{ display: 'flex' }}>
          <Space direction="vertical" align="center" size="middle" style={{ display: 'flex' }}>
            <Title level={3}>{t`This Activation Link Has Expired`}</Title>
            <Space direction="vertical" size="middle">
              <Paragraph>{t`Activation links expire when:`}</Paragraph>
              <ul>
                <li>{t`Your account has already been activated`}</li>
                <li>{t`It has been over 14 days since the invitation was sent`}</li>
                <li>{t`The organizer has revoked the invitation`}</li>
              </ul>
            </Space>
          </Space>
          <Button type="primary" onClick={onLogin}>{t`Back to Login`}</Button>
        </Space>
      </Layout>
    );
  }

  const title = isAccepted ? t`You’ve Accepted Your Invite!` : t`Accept Your Invite`;

  return (
    <Layout>
      <Space direction="vertical" align="center" size="middle" style={{ display: 'flex' }}>
        <Space direction="vertical" align="center" size="middle" style={{ display: 'flex' }}>
          <Title level={3}>{title}</Title>
          <Paragraph style={{ textAlign: 'center' }}>{description}</Paragraph>
        </Space>
        {!isAccepted && (
          <Button
            type="primary"
            onClick={handleAcceptInvite}
            disabled={isAcceptInProgress}
          >{t`Accept Invite`}</Button>
        )}
        {isAccepted && <Button type="primary" onClick={onLogin}>{t`Login`}</Button>}
      </Space>
    </Layout>
  );
};

export default AcceptInvite;
