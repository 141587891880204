import React from 'react';

import AuthForgotPassword from 'common/components/Auth/ForgotPassword';
import { forgotPassword as forgotPasswordFn } from 'common/services/auth/api';

import { AUTH_APP } from './service';

const ForgotPassword = () => (
  <AuthForgotPassword
    forgotPasswordFn={payload => forgotPasswordFn({ ...payload, type: AUTH_APP })}
  />
);

export default ForgotPassword;
