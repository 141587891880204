/* eslint-disable import/no-duplicates */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr-CA';
import de from 'date-fns/locale/de';
import pt from 'date-fns/locale/pt-BR';
import es from 'date-fns/locale/es';

import storageKeys from '../../types/localStorageKeys';
import { update as updateFn, retrieve as retrieveFn } from '../../services/session-service';
import {
  getDefaultLanguage,
  setLocale as setLocaleFn,
  ISO_LOCALE_MAP,
} from '../../services/translation/api';

import Context from './context';
import AntDTranslationProvider from './AntDProvider';

const ISO_DATE_LOCALE = {
  [ISO_LOCALE_MAP.en]: en,
  [ISO_LOCALE_MAP.fr_CA]: fr,
  [ISO_LOCALE_MAP.pt_BR]: pt,
  [ISO_LOCALE_MAP.de]: de,
  [ISO_LOCALE_MAP.es]: es,
};

interface Props {
  // EXP react loads translations with a '-react' suffix. Example - /translations/{locale}-react.json
  // Once all of the angular is migrated to react, we can remove this prop and load translations via /translations/{locale}.json
  suffix?: string;
  children: React.ReactNode;
}

/**
 * A context provider for the translation (language and locale). You can access
 * the context with common/hooks/useTranslation.
 */
const TranslationProvider = ({ suffix = '', children }: Props) => {
  const defaultLanguage = getDefaultLanguage();

  const [stored, setStored] = useState({
    language: defaultLanguage,
    locale: ISO_LOCALE_MAP[defaultLanguage],
    dateLocale: ISO_DATE_LOCALE[defaultLanguage],
  });

  const updateStored = useCallback(
    async (language: string) => {
      await setLocaleFn(language, suffix);
      setStored({
        language,
        locale: ISO_LOCALE_MAP[language],
        dateLocale: ISO_DATE_LOCALE[ISO_LOCALE_MAP[language]],
      });
    },
    [suffix]
  );

  useEffect(() => {
    async function effect() {
      const language = (await retrieveFn())?.language || getDefaultLanguage();
      await updateStored(language);
    }
    effect();
  }, [updateStored]);

  const setLanguage = useCallback(
    async language => {
      await Promise.all([updateStored(language), updateFn({ language })]);
      // TODO: Remove this once we're fully off Angular
      // This will set the language for Angular pages to use
      window.localStorage.setItem(storageKeys.language, language);
    },
    [updateStored]
  );

  const value = useMemo(() => ({ ...stored, setLanguage }), [stored, setLanguage]);

  return (
    <Context.Provider value={value}>
      <AntDTranslationProvider locale={stored.locale}>{children}</AntDTranslationProvider>
    </Context.Provider>
  );
};

export default TranslationProvider;
