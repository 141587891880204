import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { message, Form as AntForm, Typography, Input, Popover, Button } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  WarningFilled,
  CheckCircleFilled,
} from '@ant-design/icons';
import { t, jt } from 'ttag';
import { PasswordProps } from 'antd/lib/input';

import useSearchParams from '../../hooks/useSearchParams';
import Label from '../Label';
import Layout from './Layout';
import useInvite from './useInvite';
import { AcceptInvitePayload, AdminInviteStatus, User } from '../../services/auth/types';

const { Title: AntTitle, Paragraph } = Typography;
const { useForm } = AntForm;

interface Props {
  id: string;
  token: string;
  description?: string;
  checkInviteStatusFn: () => Promise<AdminInviteStatus>;
  acceptInviteFn: (payload?: AcceptInvitePayload) => Promise<User | null>;
  onUserAlreadyActivated: () => void;
  onActivated: () => void;
}

const ONE_LETTER_REGEX = /[a-zA-Z]/;
const ONE_NUMBER_REGEX = /[0-9]/;
const ONE_SPECIAL_CHARACTER_REGEX = /[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;
const EIGHT_CHARACTERS_REGEX = /.{8}/;

const Title = styled(AntTitle)`
  && {
    align-self: center;
    margin-bottom: 1.5rem;
  }
`;

const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Field = styled(AntForm.Item)`
  && {
    margin-bottom: 24px;
    @media (max-width: 600px) {
      flex-direction: row;
    }
  }
`;

const Check = styled.div<{ isValid: boolean }>`
  color: ${({ isValid }) => (isValid ? '#6EBB83' : '#ff4d4f')};
  svg {
    margin-right: 8px;
  }
`;

const Wrapper = styled.div`
  .ant-card .ant-card-body {
    width: 634px;
  }
`;

const validatePassword = (password: string) => {
  const hasLetter = !!password.match(ONE_LETTER_REGEX);
  const hasNumber = !!password.match(ONE_NUMBER_REGEX);
  const hasSpecialCharacter = !!password.match(ONE_SPECIAL_CHARACTER_REGEX);
  const hasEightCharacters = !!password.match(EIGHT_CHARACTERS_REGEX);
  const isValid = password && hasLetter && hasNumber && hasSpecialCharacter && hasEightCharacters;

  return { hasLetter, hasNumber, hasSpecialCharacter, hasEightCharacters, isValid };
};

interface PasswordCheckProps {
  children: React.ReactNode;
  isValid: boolean;
}

const PasswordCheck = ({ children, isValid }: PasswordCheckProps) => (
  <Check isValid={isValid}>
    {isValid && <CheckCircleFilled />}
    {!isValid && <WarningFilled />}
    {children}
  </Check>
);

const PasswordInstructions = ({ password }: { password: string }) => {
  const { hasEightCharacters, hasLetter, hasNumber, hasSpecialCharacter } = validatePassword(
    password
  );

  return (
    <div data-testid="passwordInstructions">
      <PasswordCheck isValid={hasEightCharacters}>{t`Minimum 8 characters`}</PasswordCheck>
      <PasswordCheck isValid={hasLetter}>{t`Contains 1 letter`}</PasswordCheck>
      <PasswordCheck isValid={hasNumber}>{t`Contains 1 number`}</PasswordCheck>
      <PasswordCheck isValid={hasSpecialCharacter}>{t`Contains 1 special character`}</PasswordCheck>
    </div>
  );
};

interface PasswordFieldProps extends PasswordProps {
  showInstructions: boolean;
}

const PasswordField = ({ value, onChange, showInstructions, ...rest }: PasswordFieldProps) => (
  <Popover
    open={showInstructions}
    placement="bottomLeft"
    content={<PasswordInstructions password={value as string} />}
  >
    <Input.Password value={value} data-testid="password" onChange={onChange} {...rest} />
  </Popover>
);

const visibilityIcon = (visible: boolean) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />);

const ActivateAccount = ({
  id,
  token,
  description = '',
  checkInviteStatusFn,
  acceptInviteFn,
  onUserAlreadyActivated,
  onActivated,
}: Props) => {
  const [form] = useForm();
  const { validateFields, getFieldValue } = form;
  const [inputFocus, setInputFocus] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const history = useHistory();
  const query = useSearchParams();
  const { isValidating, status, accept, isAcceptInProgress } = useInvite(
    token,
    id,
    checkInviteStatusFn,
    acceptInviteFn
  );

  useEffect(() => {
    if (!isValidating && !status?.isNewUser) {
      onUserAlreadyActivated?.();
    }
  }, [isValidating, status, history, onUserAlreadyActivated]);

  const handleOnFocus = () => {
    setShowPasswordPopup(true);
    setInputFocus(true);
  };

  const handleOnBlur = () => {
    setShowPasswordPopup(false);
    setInputFocus(false);
  };

  const onFinish = async () => {
    const { firstName, lastName, password } = await validateFields();

    await accept({ firstName, lastName, password });
    message.success(t`Successfully activated account.`);
    onActivated();
  };

  const termsOfUseLink = (
    <a
      key="termsOfUseLink"
      href="https://eventmobi.com/terms-of-use/"
      rel="nofollow noopener noreferrer"
      target="_blank"
    >{t`Terms of Use`}</a>
  );

  const privacyPolicyLink = (
    <a
      key="privacyPolicyLink"
      href="https://eventmobi.com/privacy-policy/"
      rel="nofollow noopener noreferrer"
      target="_blank"
    >{t`Privacy Policy`}</a>
  );

  if (isValidating || !status) {
    return null;
  }

  const [email, firstName, lastName] = [
    query.get('email'),
    query.get('first_name'),
    query.get('last_name'),
  ];

  return (
    <Wrapper>
      <Layout>
        <Form
          form={form}
          layout="vertical"
          requiredMark={false}
          initialValues={{
            email,
            firstName,
            lastName,
            password: '',
            confirmPassword: '',
          }}
          onFinish={onFinish}
        >
          <Title level={4}>{t`Activate your Account`}</Title>
          {description && <Paragraph style={{ textAlign: 'center' }}>{description}</Paragraph>}
          <Field label={<Label>{t`Email Address`}</Label>} name="email" style={{ marginBottom: 0 }}>
            <Input disabled data-testid="email" />
          </Field>
          <Paragraph
            type="secondary"
            style={{ marginBottom: 24 }}
          >{t`Email cannot be changed. If you wish to use a different email, please contact your event organizer to update your profile email.`}</Paragraph>
          <Field
            label={<Label isRequired>{t`First Name`}</Label>}
            name="firstName"
            rules={[{ required: true, message: t`First Name is required.` }]}
          >
            <Input disabled={!!firstName} data-testid="firstName" />
          </Field>
          <Field
            label={<Label isRequired>{t`Last Name`}</Label>}
            name="lastName"
            rules={[{ required: true, message: t`Last Name is required.` }]}
          >
            <Input disabled={!!lastName} data-testid="lastName" />
          </Field>
          <Field
            label={<Label isRequired>{t`Password`}</Label>}
            name="password"
            validateTrigger={['onChange', 'onFocus', 'onBlur']}
            rules={[
              { required: true, message: t`Password is required.` },
              {
                validator: (_, value) => {
                  const { isValid } = validatePassword(value);

                  if (isValid) {
                    setShowPasswordPopup(false);
                    return Promise.resolve();
                  }
                  if (inputFocus) {
                    setShowPasswordPopup(true);
                  }
                  return Promise.reject(new Error(''));
                },
              },
            ]}
          >
            <PasswordField
              showInstructions={showPasswordPopup}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          </Field>
          <Field
            label={<Label isRequired>{t`Confirm New Password`}</Label>}
            name="confirmPassword"
            rules={[
              { required: true, message: t`Password is required.` },
              {
                validator: (_, value) => {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t`Passwords must match.`));
                },
              },
            ]}
          >
            <Input.Password data-testid="confirmPassword" iconRender={visibilityIcon} />
          </Field>
          <Paragraph>
            {jt`By activating your account you agree to our ${termsOfUseLink} and ${privacyPolicyLink}.`}
          </Paragraph>
          <Field shouldUpdate noStyle>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  isAcceptInProgress ||
                  !(
                    getFieldValue('firstName') &&
                    getFieldValue('lastName') &&
                    getFieldValue('password') &&
                    getFieldValue('confirmPassword')
                  )
                }
                loading={isAcceptInProgress}
                style={{ marginBottom: '16px' }}
                data-testid="btnActivate"
              >
                {isAcceptInProgress ? t`Activating Account...` : t`Activate Account`}
              </Button>
            )}
          </Field>
        </Form>
      </Layout>
    </Wrapper>
  );
};

export default ActivateAccount;
