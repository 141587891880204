import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Alert, Button, Form as AntForm, Input, message, Typography } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { t } from 'ttag';
import { useHistory } from 'react-router-dom';

import * as FlagService from '../../../services/flags/api';
import { Flag } from '../../../services/flags/types';
import { User, Session, LoginPayload } from '../../../services/auth/types';
import useSearchParams from '../../../hooks/useSearchParams';
import Label from '../../Label';

import Layout from '../Layout';

const ERROR_MESSAGES_BY_CODE = () => ({
  invalid_email_or_password: t`Incorrect email and/or password.`,
  too_many_failed_logins: t`Too many failed login attempts. Please try again in 5 minutes.`,
  banned_user: t`Your account has been banned. Contact an event organizer to learn more.`,
});

const { Title: AntTitle } = Typography;

const Title = styled(AntTitle)`
  && {
    align-self: center;
    margin-bottom: 1.5rem;
  }
`;

const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PasswordItem = styled(AntForm.Item)`
  position: 'relative';
`;

const PasswordLink = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  padding: 0px 0px 10px 0px;
`;

export interface Props {
  title: string;
  userFlagFn: typeof FlagService.user;
  loginFn: (payload: LoginPayload) => Promise<User>;
  setUserAndFlags: (user: Omit<User | Session, 'language'>, flags: Flag[]) => void;
  afterLogin?: (user: User) => Promise<void>;
  redirectUrl?: string;
}

const { useForm } = AntForm;

const PasswordIconRenderer = (visible: boolean) =>
  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

const LoginPresentation = ({
  title,
  userFlagFn,
  loginFn,
  setUserAndFlags,
  afterLogin = null,
  redirectUrl = '/',
}: Props) => {
  const [form] = useForm();
  const { validateFields, getFieldValue } = form;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const query = useSearchParams();

  const redirect = useCallback(
    (redirectTo = '/') => {
      const next = query.get('next');
      if (next && next !== '/login') {
        return history.push(next);
      }

      return history.push(redirectTo);
    },
    [history, query]
  );

  const onFinish = async () => {
    setError('');
    setLoading(true);

    const values = await validateFields();
    const payload = {
      email: values.email,
      password: values.password,
    };

    try {
      const user = await loginFn(payload);
      const { data: flags } = await userFlagFn(user.userId);
      setUserAndFlags(user, flags);
      if (afterLogin) {
        await afterLogin(user);
      }
      redirect(redirectUrl);
    } catch (err) {
      const errorMessage =
        ERROR_MESSAGES_BY_CODE()[err?.errors?.[0]?.code] ??
        t`Unknown error occurred while logging in.`;
      setError(errorMessage);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
        onFinishFailed={() => message.error(t`Please address the errors shown below`)}
        initialValues={{ email: '', password: '' }}
      >
        <Title level={4}>{title}</Title>
        {error && (
          <Alert
            message={error}
            type="error"
            style={{ marginBottom: '24px', width: '100%', border: 0 }}
            showIcon
          />
        )}
        <AntForm.Item
          label={<Label isRequired>{t`Email Address`}</Label>}
          name="email"
          rules={[
            { required: true, message: t`Email Address is required.` },
            { type: 'email', message: t`Invalid email address.` },
          ]}
        >
          <Input data-testid="email" />
        </AntForm.Item>
        <PasswordItem>
          <AntForm.Item
            label={<Label isRequired>{t`Password`}</Label>}
            name="password"
            rules={[{ required: true, message: t`Password is required.` }]}
          >
            <Input.Password data-testid="password" iconRender={PasswordIconRenderer} />
          </AntForm.Item>
          <PasswordLink type="link" onClick={() => history.replace('/forgot-password')}>
            {t`Forgot your password?`}
          </PasswordLink>
        </PasswordItem>
        <AntForm.Item shouldUpdate noStyle>
          {() => (
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={!(getFieldValue('email') && getFieldValue('password'))}
            >
              {loading ? t`Logging in...` : t`Log in`}
            </Button>
          )}
        </AntForm.Item>
      </Form>
    </Layout>
  );
};

export default LoginPresentation;
