import React from 'react';
import { ThemeProvider } from 'styled-components';

import ButtonStyle from './button';
import PaginationStyle from './pagination';
import RichTextEditorStyle from './richTextEditor';
import BodyStyle from './body';
import { Theme } from './types';

const defaultTheme = { primaryColor: '#6EBB83' };

// All component Overrides should go inside following component
//
// A `theme` can be passed down to the component as prop, when not
// provided, it will use the defaults.
const Overrides = ({ theme = defaultTheme }: Theme) => (
  <ThemeProvider theme={theme}>
    <ButtonStyle />
    <PaginationStyle />
    <RichTextEditorStyle />
    <BodyStyle />
  </ThemeProvider>
);

export default Overrides;
