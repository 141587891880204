import React from 'react';
import { Route } from 'react-router-dom';

import HTTP401 from './HTTP401';
import HTTP403 from './HTTP403';
import HTTP404 from './HTTP404';

enum RoutePath {
  Unauthorized = '/unauthorized',
  Forbidden = '/forbidden',
  Default = '*',
}

const ErrorRoutes = [
  <Route key={RoutePath.Unauthorized} path={RoutePath.Unauthorized} exact>
    <HTTP401 />
  </Route>,
  <Route key={RoutePath.Forbidden} path={RoutePath.Forbidden} exact>
    <HTTP403 />
  </Route>,
  <Route key={RoutePath.Default} path={RoutePath.Default}>
    <HTTP404 />
  </Route>,
];

export default ErrorRoutes;
