import React, { useEffect } from 'react';
import { t } from 'ttag';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';

import useTranslation from '../../hooks/useTranslation';
import { clean as cleanSession } from '../../services/session-service';
import useSearchParams from '../../hooks/useSearchParams';

import Layout from './Layout';

const HTTP401 = () => {
  useTranslation();
  const history = useHistory();
  const query = useSearchParams();
  const next = query.get('next');

  useEffect(() => {
    cleanSession();
  }, []);

  return (
    <Layout
      title={t`Oops, You don't have permission to access the page anymore.`}
      body={t`Please try logging in again or contact to your event organizer.`}
    >
      <Button
        type="primary"
        onClick={() => history.replace(`/login?next=${next && next !== '/login' ? next : ''}`)}
      >
        {t`Try Login`}
      </Button>
    </Layout>
  );
};

export default HTTP401;
