import React from 'react';
import { batch, useDispatch } from 'react-redux';

import { user as userFlagFn } from '../../../services/flags/api';
import { setUser as setUserFlags } from '../../../state/flags';
import { setUser } from '../../../state/user';

import Presentation from './Presentation';
import { LoginPayload, User } from '../../../services/auth/types';

interface Props {
  title: string;
  redirectUrl?: string;
  loginFn: (payload: LoginPayload) => Promise<User>;
  afterLogin?: (user: User) => Promise<void>;
}

const Login = ({ title, loginFn, afterLogin = null, redirectUrl = '/' }: Props) => {
  const dispatch = useDispatch();

  return (
    <Presentation
      title={title}
      userFlagFn={userFlagFn}
      loginFn={loginFn}
      redirectUrl={redirectUrl}
      setUserAndFlags={(user, flags) =>
        batch(() => {
          dispatch(setUser(user));
          dispatch(setUserFlags(flags));
        })
      }
      afterLogin={afterLogin}
    />
  );
};

export default Login;
