import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button, Form as AntForm, Input, message, Typography, Space } from 'antd';
import { t, jt } from 'ttag';

import useTranslation from '../../../hooks/useTranslation';
import { SUPPORT_EMAIL } from '../../../services/support/types';
import type * as AuthService from '../../../services/auth/api';
import Label from '../../Label';

import Layout from '../Layout';
import { ForgotPasswordPayload } from '../../../services/auth/types';

const { Title: AntTitle, Text } = Typography;
const { useForm } = AntForm;

const Title = styled(AntTitle)`
  && {
    text-align: center;
    margin-bottom: 1.5rem;
  }
`;

const Form = styled(AntForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Field = styled(AntForm.Item)`
  && {
    margin-bottom: 24px;
  }
`;

const Help = styled.div`
  text-align: center;

  .ant-typography {
    font-size: 12px;
    margin-top: 16px;
  }
`;

export interface Props {
  forgotPasswordFn: (
    payload: Pick<ForgotPasswordPayload, 'email'>
  ) => ReturnType<typeof AuthService.forgotPassword>;
}

const ForgotPassword = ({ forgotPasswordFn }: Props) => {
  const history = useHistory();
  const [form] = useForm<{ email: string }>();
  const { validateFields, getFieldValue } = form;
  const [loading, setLoading] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);

  useTranslation();

  const onFinish = async () => {
    setLoading(true);
    const values = await validateFields();
    const payload = {
      email: values.email,
    };

    try {
      await forgotPasswordFn(payload);
      setSentEmail(true);
    } catch (e) {
      message.error(t`Unexpected error while sending reset password email.`);
    }
    setLoading(false);
  };

  const emailBolded = (
    <Text key="email" strong>
      {getFieldValue('email')}
    </Text>
  );

  const contactSupport = (
    <a key="supportEmail" href={`mailto:${SUPPORT_EMAIL}`}>{t`Contact Support.`}</a>
  );

  return (
    <Layout>
      {!sentEmail && (
        <Form
          form={form}
          layout="vertical"
          hideRequiredMark
          onFinish={onFinish}
          onFinishFailed={() => message.error(t`Please address the errors shown below`)}
          initialValues={{ email: '' }}
        >
          <Title level={4}>{t`Forgot Your Password?`}</Title>
          <Text
            style={{ marginBottom: '24px' }}
          >{t`Enter the email address you use to log in and we'll email you instructions on how to reset your password.`}</Text>
          <Field
            label={<Label isRequired>{t`Email Address`}</Label>}
            name="email"
            rules={[
              { required: true, message: t`Email Address is required.` },
              { type: 'email', message: t`Invalid email address.` },
            ]}
          >
            <Input data-testid="email" />
          </Field>
          <Field shouldUpdate noStyle>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading || !getFieldValue('email')}
                style={{ marginBottom: '16px' }}
              >
                {loading ? t`Sending Reset Instructions...` : t`Send Reset Instructions`}
              </Button>
            )}
          </Field>
          <Button onClick={() => history.replace('/')}>{t`Back to Login`}</Button>
        </Form>
      )}
      {sentEmail && (
        <Space direction="vertical" size="middle">
          <Title level={4}>{t`Check Your Email`}</Title>
          <Text
            style={{ marginBottom: '24px' }}
          >{jt`We have sent an email to ${emailBolded} with instructions on how to reset your password. If you don't receive the email within a few minutes, please check your spam folder.`}</Text>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => history.replace('/')}
            style={{ width: '100%' }}
          >
            {t`Back to Login Form`}
          </Button>
          <Help>
            <Text>{jt`Need help? ${contactSupport}`}</Text>
          </Help>
        </Space>
      )}
    </Layout>
  );
};

export default ForgotPassword;
