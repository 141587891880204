import React from 'react';
import { useDispatch } from 'react-redux';

import { get as getFn, update as updateFn } from '../../services/auth/api';
import { UpdatePayload } from '../../services/auth/types';
import { updateUser } from '../../state/user';
import useTranslation from '../../hooks/useTranslation';
import { update } from '../../services/session-service';

import Presentation from './Presentation';

const Profile = () => {
  const dispatch = useDispatch();
  const { setLanguage } = useTranslation();

  const onUpdate = async (payload: Partial<UpdatePayload>) => {
    const { data } = await updateFn(payload);

    dispatch(updateUser(data));
    await update({ ...data });

    if (payload.language) {
      await setLanguage(payload.language);
    }
  };

  return <Presentation getFn={getFn} updateFn={onUpdate} />;
};

export default Profile;
