import localforage from 'localforage';
import { Session, User } from './auth/types';

enum Store {
  key = 'session',
}

type Payload = {
  storeName: string;
  description: string;
};

type SessionUser = Session & User;

const defaultStore: Payload = {
  storeName: 'auth',
  description: 'local store to keep authentication related information',
};

const local = () => localforage.createInstance({ ...defaultStore, name: 'session' });

export const store = async (payload: SessionUser) => {
  const src = local();

  return src.setItem(Store.key, payload);
};

export const retrieve = async (): Promise<SessionUser> => {
  const src = local();

  return src.getItem(Store.key);
};

export const update = async (payload: Partial<SessionUser>) => {
  const prev = await retrieve();
  return store({ ...prev, ...payload });
};

export const clean = () => {
  const src = local();

  return src.removeItem(Store.key);
};
