import Portal from '../../types/Portal';
import apiFn from '../api-service';
import { retrieve } from '../session-service';

import {
  ForgotPasswordPayload,
  AdminInviteStatus,
  AcceptAdminInvitePayload,
  ResetPasswordPayload,
  Session,
  User,
  UpdatePayload,
  AdminInviteStatusPayload,
} from './types';

const authApi = (endpoint: string) => apiFn(`/api/uapi/${endpoint}`);
const identityApi = (endpoint: string) => apiFn(`/api/uapi/identity/${endpoint}`);

export const isAuthenticated = async (): Promise<boolean> => {
  const session = await retrieve();
  return session !== null && typeof session.userId !== 'undefined' && session.userId !== null;
};

export const validateToken = (token: string, type: string): APIResponse<{ token: string }[]> =>
  identityApi('validate-token').get(`?type=${type}&token=${token}`);

export const resetPassword = (payload: ResetPasswordPayload): APIResponse<void> =>
  identityApi('reset-password').post('', { ...payload });

export const forgotPassword = (payload: ForgotPasswordPayload) =>
  identityApi('forgot-password').post('', { ...payload });

export const checkAdminInviteStatus = (
  payload: AdminInviteStatusPayload
): APIResponse<AdminInviteStatus> =>
  identityApi('admin-invite-status')
    .post('', { ...payload })
    .then(response => response.data);

export const acceptAdminInvite = (payload: AcceptAdminInvitePayload): APIResponse<User | null> =>
  identityApi('accept-admin-invite')
    .post('', { ...payload })
    .then(response => response.data);

export const logout = (portalType: Portal): APIResponse<null> => {
  const type = portalType === Portal.CompanyPortal ? 'company-portal' : '';

  return authApi(type)
    .post('logout')
    .then(response => response.data);
};

export const update = (payload: Partial<UpdatePayload>): APIResponse<Session> =>
  identityApi('me')
    .patch('', { ...payload })
    .then(({ data }) => data);

export const get = (): APIResponse<Session> =>
  identityApi('me')
    .get('')
    .then(({ data }) => data);
