import React, { useEffect, Suspense, useState } from 'react';
import {
  BrowserRouter as Router,
  matchPath,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { Provider, useDispatch, batch } from 'react-redux';
import 'antd/dist/antd.css';
import styled from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import TranslationProvider from 'common/components/Translation/Provider';
import ErrorRoutes from 'common/components/ErrorRoutes/Routes';
import PrivateRoute from 'common/components/Auth/PrivateRoute';
import { isAuthenticated as isAuthenticatedFn } from 'common/services/auth/api';
import Loading from 'common/components/Loading';
import { retrieve } from 'common/services/session-service';
import { user as userFlagsFn, system as systemFlagsFn } from 'common/services/flags/api';
import { setUser } from 'common/state/user';
import { setUser as setFlags, setSystem as setSystemFlags } from 'common/state/flags';
import useTranslation from 'common/hooks/useTranslation';
import { useCompanyQuery } from 'common/services/companies/index';
import { useEventQuery } from 'common/services/events/index';
import { setEvent } from 'common/state/event';
import { setPortal } from 'common/state/system';
import Portal from 'common/types/Portal';
import { Provider as AppcuesProvider } from 'common/components/Appcues';

import store from '~state/store';
import RoutePath from '~types/RoutePath';
import PrivateLayout from '~components/PrivateLayout';
import { set as setCompanyEvent } from '~state/companyEvent';
import AuthRoutes from '~domains/Auth/Routes';
import CompanyEvent from '~domains/CompanyEvent';
import Settings from '~domains/Settings';
import SideNavigationRoutes from '~components/SideNavigationRoutes';

import GlobalStyles from './overrides';
import './setup-sentry';

const Wrap = styled.div`
  height: 100vh;
  font-family: 'Open Sans', sans-serif;
  & > p {
    margin: 0;
    padding: 0;
  }
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const useBootstrap = () => {
  const { setLanguage } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const path = matchPath<{
    eventId?: string;
    companyId?: string;
  }>(location.pathname, {
    path: RoutePath.Company,
    exact: false,
  });
  const { data: company, isLoading: isCompanyLoading } = useCompanyQuery(
    path?.params.eventId,
    path?.params.companyId
  );
  const { data: event, isLoading: isEventLoading } = useEventQuery(path?.params?.eventId);

  useEffect(() => {
    dispatch(setPortal(Portal.CompanyPortal));
  }, [dispatch]);

  useEffect(() => {
    async function effect() {
      try {
        const {
          location: { pathname },
        } = history;

        if (pathname === RoutePath.Unauthorized) {
          setIsLoading(false);
          return;
        }

        const isAuthenticated = await isAuthenticatedFn();

        if (isAuthenticated) {
          const { language, ...user } = await retrieve();
          const { data: systemFlags } = await systemFlagsFn();
          const { data: flags } = await userFlagsFn(user.userId);
          batch(() => {
            dispatch(setUser(user));
            dispatch(setFlags(flags));
            dispatch(setSystemFlags(systemFlags));
          });

          setLanguage(language);
        }

        if (isAuthenticated && !isCompanyLoading && !isEventLoading) {
          const eventData = { ...event?.data };
          if (company && eventData) {
            batch(() => {
              dispatch(setEvent(eventData));
              dispatch(
                setCompanyEvent({
                  event: eventData,
                  ...company.data,
                })
              );
            });
          }
        }
      } finally {
        setIsLoading(false);
      }
    }

    effect();
  }, [history, dispatch, setLanguage, company, event, isCompanyLoading, isEventLoading]);

  return { isLoading };
};

const Base = () => {
  const history = useHistory();

  useEffect(() => {
    async function effect() {
      const isAuthenticated = await isAuthenticatedFn();
      if (isAuthenticated) {
        history.replace(RoutePath.CompanyEvent);
      } else {
        history.replace(RoutePath.Login);
      }
    }

    effect();
  }, [history]);

  return null;
};

const Routes = () => {
  const { isLoading } = useBootstrap();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Switch>
      <Route path="/" exact>
        <Base />
      </Route>
      {AuthRoutes}
      <PrivateRoute path={RoutePath.CompanyEvent} exact>
        <PrivateLayout>
          <CompanyEvent />
        </PrivateLayout>
      </PrivateRoute>
      <PrivateRoute path={RoutePath.Company}>
        <PrivateLayout>
          <SideNavigationRoutes />
        </PrivateLayout>
      </PrivateRoute>
      <PrivateRoute path={RoutePath.Settings} exact>
        <PrivateLayout>
          <Settings />
        </PrivateLayout>
      </PrivateRoute>
      {ErrorRoutes}
    </Switch>
  );
};

const App = () => (
  <Wrap>
    <GlobalStyles />
    <TranslationProvider>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <AppcuesProvider>
            <DndProvider backend={HTML5Backend}>
              <Router>
                <Suspense fallback={<Loading />}>
                  <Routes />
                </Suspense>
              </Router>
            </DndProvider>
          </AppcuesProvider>
        </Provider>
      </QueryClientProvider>
    </TranslationProvider>
  </Wrap>
);

export default App;
