import React from 'react';
import { useHistory } from 'react-router-dom';
import { t } from 'ttag';

import useSearchParams from 'common/hooks/useSearchParams';
import ActivateAccountCommon from 'common/components/Auth/ActivateAccount';
import {
  checkAdminInviteStatus as checkAdminInviteStatusFn,
  acceptAdminInvite as acceptAdminInviteFn,
} from 'common/services/auth/api';

import RoutePath from '~types/RoutePath';
import { AcceptInvitePayload } from 'common/services/auth/types';

const ActivateAccount = () => {
  const history = useHistory();
  const query = useSearchParams();
  const token = query.get('token');
  const companyId = query.get('company_id');
  const companyName = query.get('company_name');

  return (
    <ActivateAccountCommon
      id={companyId}
      token={token}
      description={t`Welcome to ${companyName} Company portal, the platform used to manage your company’s information. Please note that this credential will be used throughout not only Company Portal, but also any events that you are joining with this email.`}
      checkInviteStatusFn={async () => {
        const { data } = await checkAdminInviteStatusFn({ token, companyId });
        return data;
      }}
      acceptInviteFn={async (payload: AcceptInvitePayload) => {
        const { data } = await acceptAdminInviteFn(
          payload ? { ...payload, token, companyId } : { token, companyId }
        );
        return data;
      }}
      onActivated={() => history.replace(RoutePath.CompanyEvent)}
      onUserAlreadyActivated={() => history.replace(RoutePath.Login)}
    />
  );
};

export default ActivateAccount;
