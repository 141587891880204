import { createContext } from 'react';

// This is exported separately from the parent domain so that we don't run into
// any circular dependencies
type ContextType = {
  locale: string;
  language: string;
  setLanguage?: (language: any) => Promise<void> | void;
};
export default createContext<ContextType>({
  locale: '',
  language: '',
});
