import apiFn from '../api-service';

import { Flag } from './types';

export const event = (id: number) => {
  const api = apiFn(`/api/cms/v1/views/events/${id.toString()}/feature-flag-values`);

  return api.get('').then(response => response.data);
};

export const organization = (id: number) => {
  const api = apiFn(`/api/cms/v1/views/organizations/${id.toString()}/feature-flag-values`);

  return api.get('').then(response => response.data);
};

export const user = async (id: number): APIResponse<Flag[]> => {
  const api = apiFn(`/api/cms/v1/views/users/${id.toString()}/feature-flag-values`);

  return api.get('').then(response => response.data);
};

export const system = async (): APIResponse<Flag[]> => {
  const api = apiFn(`/api/v1/views/sys/feature-flag-values`);

  return api.get('').then(response => response.data);
};
