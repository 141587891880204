import apiFn from '../api-service';
import { ConfigurationEntry, ConfigurationKey } from './types';

export const keys = (): APIResponse<ConfigurationKey[]> =>
  apiFn('/api/cms/v1/configuration_keys')
    .get('')
    .then(response => response.data);

const api = (eventId: string) => {
  if (!eventId) {
    throw new Error('event id is required');
  }
  return apiFn(`/api/cms/v1/events/${eventId}/config`);
};

export const get = (eventId: string): APIResponse<ConfigurationEntry[]> =>
  api(eventId)
    .get('')
    .then(response => response.data);

export const update = async (
  eventId: string,
  configId: number,
  payload: any
): APIResponse<ConfigurationEntry> => {
  if (!configId) {
    throw new Error('config id is required');
  }

  if (!payload || (typeof payload === 'object' && Object.keys(payload).length === 0)) {
    throw new Error('payload object is required');
  }

  const response = await api(eventId).patch(`/${configId}`, payload);
  return response.data;
};
