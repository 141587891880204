import { snakeCase } from 'snake-case';

import apiFn from '../api-service';
import { Company, CreateCompanyPayload, PossibleIncludes, UpdateCompanyPayload } from './types';

const api = (eventId: string | number) => apiFn(`/api/uapi/events/${eventId}/companies`);

export const list = <TIncludes extends PossibleIncludes = undefined>(
  eventId: string | number,
  payload: { offset?: number; limit?: number } = {},
  query: string = '',
  include: PossibleIncludes[] = ['groups', 'logoImage', 'location']
): APIResponse<Company<TIncludes>[]> => {
  const params = new URLSearchParams(query);

  if (include.length > 0) {
    params.set('include', include.map(v => snakeCase(v)).join(','));
  }

  if (typeof payload.offset !== 'undefined' && payload.offset > 0) {
    params.set('page', `${payload.offset / payload.limit}`);
  }

  return api(eventId)
    .get(null, { params })
    .then(response => response.data);
};

export const create = (
  eventId: string | number,
  payload: CreateCompanyPayload
): APIResponse<Company> =>
  api(eventId)
    .post('', payload)
    .then(response => response?.data?.data);

export const get = <TIncludes extends PossibleIncludes[]>(
  eventId: string | number,
  companyId: string,
  include: TIncludes
): APIResponse<Company<TIncludes[number]>> =>
  api(eventId)
    .get(companyId, {
      params: {
        include: include.map(v => snakeCase(v)).join(','),
      },
    })
    .then(response => response.data);

export const remove = (eventId: string | number, companyId: string): APIResponse<null> =>
  api(eventId)
    .delete(companyId)
    .then(response => response.data);

export const update = (
  eventId: string | number,
  companyId: string,
  payload: DeepPartial<UpdateCompanyPayload>
): APIResponse<Company<PossibleIncludes>> =>
  api(eventId)
    .patch(companyId, payload)
    .then(response => response.data);
