/* global DEVELOPMENT */
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import system from 'common/state/system';
import user from 'common/state/user';
import flags from 'common/state/flags';
import organization from 'common/state/organization';
import event from 'common/state/event';

import companyEvent from './companyEvent';

const middleware = [];

if (DEVELOPMENT) {
  middleware.push(createLogger());
}

const store = configureStore({
  reducer: { system, user, flags, organization, event, companyEvent },
  devTools: DEVELOPMENT,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
});

export default store;
