/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import Portal from '../types/Portal';
import { setUser } from './user';

interface State {
  isLoggedIn: boolean;
  portal: Portal;
}

const initialState: State = {
  isLoggedIn: false,
  portal: null,
};

const slice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setPortal(state, { payload: portal }) {
      return {
        ...state,
        portal,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(setUser, (state, { payload }) => {
      state.isLoggedIn = !!payload?.email;
    });
  },
});

export default slice.reducer;

export const getIsLoggedIn = state => state[slice.name];
export const checkPortal = state => ({
  isExperiencePortal: state[slice.name]?.portal === Portal.Experience,
  isCompanyPortal: state[slice.name]?.portal === Portal.CompanyPortal,
});
export const { setPortal } = slice.actions;
