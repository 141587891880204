import { useQuery } from '@tanstack/react-query';
import { createQueryDefinition } from '../queryBuilder';
import { get as getFn } from './api';

export const GetEventQuery = createQueryDefinition(
  (eventId: string) => [eventId, 'event'] as const,
  ({ queryKey: [eventId] }) => getFn(eventId)
);

export const useEventQuery = (eventId: string) =>
  useQuery({
    queryKey: GetEventQuery.getQueryKey(eventId),
    queryFn: GetEventQuery.queryFn,
    enabled: !!eventId,
  });
