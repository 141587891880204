import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const Emphasis = styled(Text)`
  color: inherit;
  font-size: 0.8rem;
  font-style: italic;
  text-transform: none;
`;

export default Emphasis;
