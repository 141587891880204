import React, { ReactNode } from 'react';
import { t } from 'ttag';
import { Typography } from 'antd';

const { Text } = Typography;

type Props = {
  children: ReactNode;
  isRequired?: boolean;
  disabled?: boolean;
  info?: ReactNode;
};

const Label = ({ children, isRequired = false, disabled = false, info = null }: Props) => (
  <span style={{ display: 'block' }}>
    <span style={{ display: 'flex', gap: 5 }}>
      <Text disabled={disabled} strong>
        {children}
      </Text>
      {isRequired && <Text disabled italic>{`(${t`required`})`}</Text>}
    </span>
    {info && <Text type="secondary">{info}</Text>}
  </span>
);

export default Label;
