import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import {
  Card as AntCard,
  Divider as AntDivider,
  List as AntList,
  Row,
  Col,
  Space,
  Typography,
  Image,
  message,
} from 'antd';
import { t, jt } from 'ttag';
import { RightOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { isAfter, isBefore } from 'date-fns';
import { generatePath, useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import BGTopLeft from 'common/assets/login-bg-top-left.png';
import BGBottomRight from 'common/assets/login-bg-bottom-right.png';
import HTTP403 from 'common/components/ErrorRoutes/HTTP403';
import { setUser } from 'common/state/user';
import { setEvent } from 'common/state/event';
import { clean } from 'common/services/session-service';
import Portals from 'common/types/Portal';
import { logout } from 'common/services/auth/api';
import { get as getEventFn } from 'common/services/events/api';

import UpComingCompanyEventImage from '~assets/upcoming-company-event.png';
import OnGoingCompanyEventImage from '~assets/ongoing-company-event.png';
import PastCompanyEventImage from '~assets/past-company-event.png';
import { useCompanyEventsQuery } from '~services/companyEvents';
import { CompanyEvent as CompanyEventType } from '~services/companyEvents/type';
import { set as setCompanyEvent } from '~state/companyEvent';
import RoutePath from '~types/RoutePath';
import Loading from 'common/components/Loading';

const { Text, Title } = Typography;
const LoadingContainer = styled.div`
  width: 100%;
`;
const Background = styled.div`
  background-image: url(${BGTopLeft}), url(${BGBottomRight});
  background-position: top left, bottom right;
  background-size: auto 65%;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  overflow-y: auto;
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px;
`;
const Header = styled(Title)`
  text-align: center;
  padding: 32px 48px;
  && {
    color: #595959;
    margin: 0px;
  }
`;
const HeaderSecondary = styled(Title)`
  text-align: center;
  padding: 24px 10px;
  && {
    color: #595959;
    margin: 0px;
  }
`;
const Name = styled(Text)`
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const List = styled(AntList)`
  .ant-list-item {
    padding: 16px 0px;
    &:first-child {
      padding-top: 0px;
    }
    &:last-child {
      padding-bottom: 0px;
    }
  }
`;
const Item = styled(List.Item)`
  cursor: pointer;
`;
const Divider = styled(AntDivider)`
  background-color: #8c8c8c;
  height: 22px;
  margin-right: 16px;
`;
const HeaderDivider = styled(AntDivider)`
  margin: 0px;
`;
const Card = styled(AntCard)`
  margin-bottom: 50px;
`;
const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px 48px;
  border-top: 1px solid #e8e8e8;
  background: white;
`;

const ERROR_MESSAGES = () => ({
  somethingWentWrong: t`Something went wrong. Please try again.`,
});

const EMLink = (
  <a key="em" href="https://eventmobi.com" rel="nofollow noopener noreferrer" target="_blank">
    EventMobi
  </a>
);

const FooterLinks = () => (
  <Space>
    <a
      href="https://eventmobi.com/terms-of-use/"
      rel="nofollow noopener noreferrer"
      target="_blank"
    >{t`Terms of Use`}</a>
    <AntDivider type="vertical" style={{ margin: 0, background: '#BFBFBF' }} />
    <a
      href="https://eventmobi.com/privacy-policy/"
      rel="nofollow noopener noreferrer"
      target="_blank"
    >{t`Privacy Policy`}</a>
    <div>{jt`Powered by ${EMLink}™`}</div>
  </Space>
);

interface CompanyListProps {
  data: CompanyEventType[];
  image: string;
  onCompanyEventSelect: (company: CompanyEventType) => void;
  isLoading: boolean;
  listName: string;
}

const CompanyList = ({
  data,
  image,
  onCompanyEventSelect,
  listName,
  isLoading,
}: CompanyListProps) => (
  <Card cover={<Image src={image} preview={false} />}>
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Text strong>{listName}</Text>
      <List
        loading={isLoading}
        dataSource={data}
        renderItem={(item: CompanyEventType) => (
          <Item key={item.event.id} onClick={() => onCompanyEventSelect(item)}>
            <Name>{item.name}</Name>
            <Name type="secondary">
              <Divider type="vertical" />
              {item.event.name}
            </Name>
            <RightOutlined />
          </Item>
        )}
      />
    </Space>
  </Card>
);

const CompanyEvent = () => {
  const history = useHistory();
  const { data, isLoading } = useCompanyEventsQuery([]);
  const dispatch = useDispatch();
  const upcomingCompanyEvents = data?.filter(({ event }) =>
    isAfter(new Date(event.startDate), new Date())
  );
  const pastCompanyEvents = data?.filter(({ event }) =>
    isBefore(new Date(event.endDate), new Date())
  );
  const ongoingCompanyEvents = data?.filter(
    ({ event }) =>
      isBefore(new Date(event.startDate), new Date()) &&
      isAfter(new Date(event.endDate), new Date())
  );

  const onCompanyEventSelect = useCallback(
    async (company: CompanyEventType) => {
      const event = await getEventFn(String(company.event.id));
      dispatch(setCompanyEvent(company));
      dispatch(setEvent(event.data));
      history.push(
        generatePath(RoutePath.CompanyDetail, { eventId: company.event.id, companyId: company.id })
      );
    },
    [dispatch, history]
  );

  const onLogout = async () => {
    try {
      await logout(Portals.CompanyPortal);
      dispatch(setUser(null));
      await clean();
      history.replace('/');
    } catch (err) {
      message.error(ERROR_MESSAGES().somethingWentWrong);
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    if (data?.length === 1) {
      onCompanyEventSelect(data[0]);
    }
  }, [data, onCompanyEventSelect]);

  const isEmpty = (data?.length === 0 || !data) && !isLoading;

  if (isEmpty) {
    return (
      <HTTP403
        title={t`You do not have access to the Company Portal.`}
        body={t`Please confirm that you are a company representative with portal access. If you’re an organizer, try accessing the portal through Experience Manager.`}
        ctaText={t`Try Again`}
        onClick={onLogout}
      />
    );
  }

  if (!data || data?.length === 1) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <Background>
      <Wrap>
        <Header level={2}>{t`Company Portal`}</Header>
        <HeaderDivider />
        <HeaderSecondary
          level={3}
        >{t`Select the Company Profile and Event you wish to enter:`}</HeaderSecondary>
        <Row gutter={24} justify="center">
          {upcomingCompanyEvents?.length > 0 && (
            <Col span={8}>
              <CompanyList
                image={UpComingCompanyEventImage}
                data={upcomingCompanyEvents}
                isLoading={isLoading}
                onCompanyEventSelect={onCompanyEventSelect}
                listName={t`Upcoming Events`}
              />
            </Col>
          )}
          {ongoingCompanyEvents?.length > 0 && (
            <Col span={8}>
              <CompanyList
                image={OnGoingCompanyEventImage}
                data={ongoingCompanyEvents}
                isLoading={isLoading}
                onCompanyEventSelect={onCompanyEventSelect}
                listName={t`Ongoing Events`}
              />
            </Col>
          )}
          {pastCompanyEvents?.length > 0 && (
            <Col span={8}>
              <CompanyList
                image={PastCompanyEventImage}
                data={pastCompanyEvents}
                isLoading={isLoading}
                onCompanyEventSelect={onCompanyEventSelect}
                listName={t`Past Events`}
              />
            </Col>
          )}
        </Row>
      </Wrap>
      <Footer>
        <FooterLinks />
      </Footer>
    </Background>
  );
};

export default CompanyEvent;
