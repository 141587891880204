enum RoutePath {
  Login = '/login',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  AcceptInvite = '/accept-invite',
  ActivateAccount = '/activate',
  CompanyEvent = '/company-events',
  Company = '/event/:eventId/company/:companyId',
  CompanyDetail = '/event/:eventId/company/:companyId/details',
  CompanyRepresentatives = '/event/:eventId/company/:companyId/representatives',
  LeadQualifier = '/event/:eventId/company/:companyId/lead-qualifier',
  LeadCollection = '/event/:eventId/company/:companyId/lead-collection',
  Settings = '/settings',
  ProfileSettings = '/settings?tab=profile',
  Unauthorized = '/unauthorized',
  Forbidden = '/forbidden',
  Default = '*',
}

export default RoutePath;
