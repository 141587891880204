import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { t } from 'ttag';
import * as Sentry from '@sentry/react';

import { get as getSessionFn } from '../../services/auth/api';
import { AcceptInvitePayload, AdminInviteStatus, User } from '../../services/auth/types';
import { store } from '../../services/session-service';

export const ERROR_MESSAGES = () => ({
  somethingWentWrong: t`Something went wrong. Please try again.`,
});

const useInvite = (
  token: string,
  id: string,
  checkInviteStatusFn: () => Promise<AdminInviteStatus>,
  acceptInviteFn: (payload?: AcceptInvitePayload) => Promise<User>
) => {
  const [isValidating, setIsValidating] = useState(true);
  const history = useHistory();
  const [status, setStatus] = useState<AdminInviteStatus>();
  const [isAcceptInProgress, setIsAcceptInProgress] = useState(false);

  useEffect(() => {
    async function effect() {
      try {
        if (!token || !id) {
          message.error(ERROR_MESSAGES().somethingWentWrong);
          return;
        }

        const invitationStatus = await checkInviteStatusFn();
        setStatus(invitationStatus);
      } catch (err) {
        message.error(ERROR_MESSAGES().somethingWentWrong);
        Sentry.captureException(err);
      } finally {
        setIsValidating(false);
      }
    }

    effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, token, id]);

  const accept = async (payload?: AcceptInvitePayload) => {
    setIsAcceptInProgress(true);
    try {
      if (!status.isNewUser) {
        await acceptInviteFn();
      } else {
        const response = await acceptInviteFn(payload);
        const session = await getSessionFn();
        store({ ...response, ...session.data });
      }
    } finally {
      setIsAcceptInProgress(false);
    }
  };

  return { isValidating, status, accept, isAcceptInProgress };
};

export default useInvite;
