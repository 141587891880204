import React, { useEffect, useState } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { isAuthenticated as isAuthenticatedFn } from '../../services/auth/api';

interface Props extends RouteProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children, ...rest }: Props) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    async function effect() {
      setLoading(true);
      setIsAuthenticated(await isAuthenticatedFn());
      setLoading(false);
    }

    effect();
  }, []);

  if (loading) {
    return null;
  }

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <Route {...rest}>{children}</Route>;
};

export default PrivateRoute;
