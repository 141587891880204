import apiFn from '../api-service';
import { BasicDataEvent, CmsEvent, UapiEvent } from './types';

const eventsApi = () => apiFn(`/api/uapi/events`);
const cmsEventsApi = organizationId => apiFn(`/api/cms/v1/organizations/${organizationId}/events`);
const basicEventDataApi = eventId => apiFn(`/api/cms/views/events/${eventId}/basic-data`);

export const get = (eventId: string): APIResponse<UapiEvent> =>
  eventsApi()
    .get(eventId)
    .then(response => response.data);

export const listCmsEvents = (
  organizationId,
  payload: { offset?: number; limit?: number } = {},
  query = null
): APIResponse<CmsEvent[]> => {
  const params = new URLSearchParams(query ?? {});

  if (typeof payload.offset !== 'undefined' && payload.offset > 0) {
    params.append('page', String(payload.offset / payload.limit));
  }

  return cmsEventsApi(organizationId)
    .get('', {
      params,
    })
    .then(response => response?.data);
};

// transform data to match the UAPI response
const transformCmsEvent = data => ({
  ...data,
  data: {
    ...data.data,
    configuration: {
      timezone: data.data.timezone,
    },
  },
});

export const getCmsEvent = (organizationId: string, eventId: string): APIResponse<CmsEvent> =>
  cmsEventsApi(organizationId)
    .get(eventId)
    .then(response => transformCmsEvent(response.data));

export const search = (organizationId: string, content: string) => {
  const params = {
    params: {
      search: content,
      limit: 10,
      offset: 0,
    },
  };

  return cmsEventsApi(organizationId)
    .get('', params)
    .then(response => response.data);
};

export const updateCmsEvent = (
  organizationId: string,
  eventId: number,
  data: {}
): APIResponse<CmsEvent> =>
  cmsEventsApi(organizationId)
    .patch(String(eventId), data)
    .then(response => transformCmsEvent(response.data));

// This endpoint does not require authentication and returns very basic information,
// used for pages like Live Results and Moderation
export const getBasicData = (eventId: string): APIResponse<BasicDataEvent> =>
  basicEventDataApi(eventId)
    .get('')
    .then(response => response.data);
