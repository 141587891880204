import Portal from '../../types/Portal';
import apiFn from '../api-service';

const companyPortalApi = () => apiFn(`/api/uapi/company-portal/support-chat/token`);
const expApi = () => apiFn(`/api/uapi/organizers/support-chat/token`);

// eslint-disable-next-line import/prefer-default-export
export const getVisitorToken = (
  payload: {} | { firstName: string; lastName: string; email: string },
  portalType: Portal
) => {
  const tokenApi = portalType === Portal.Experience ? expApi : companyPortalApi;
  return tokenApi()
    .post('', payload)
    .then(response => response.data?.data?.token);
};
