import React from 'react';
import { useHistory } from 'react-router-dom';

import AuthResetPassword from 'common/components/Auth/ResetPassword';
import {
  validateToken as validateTokenFn,
  resetPassword as resetPasswordFn,
} from 'common/services/auth/api';

import { AUTH_APP } from './service';

const ResetPassword = () => {
  const history = useHistory();

  return (
    <AuthResetPassword
      showReturnToLogin
      validateTokenFn={token => validateTokenFn(token, AUTH_APP)}
      resetPasswordFn={payload => resetPasswordFn({ ...payload, type: AUTH_APP })}
      afterReset={() => history.push('/')}
    />
  );
};

export default ResetPassword;
