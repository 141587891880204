/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { Role } from '../services/auth/types';

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  staffId: string;
  userRole?: Role;
}

const initialState: User = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  staffId: '',
};

const transformPayload = user => {
  let payload = null;

  if (user) {
    const { id, staffId, firstName, lastName, email, userRole } = user;

    payload = {
      id,
      firstName,
      lastName,
      email,
      staffId,
      userRole,
    };
  }

  return { payload, meta: null, error: null };
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: {
      prepare: transformPayload,
      reducer(state, { payload }) {
        state.firstName = payload.firstName;
        state.lastName = payload.lastName;
        state.email = payload.email;
        state.userRole = payload.userRole;
      },
    },
    setUser: {
      prepare: transformPayload,
      // eslint-disable-next-line consistent-return
      reducer(state, { payload }) {
        if (!payload) {
          return initialState;
        }

        state.id = payload.id;
        state.staffId = payload?.staffId ?? state.staffId;
        state.firstName = payload.firstName;
        state.lastName = payload.lastName;
        state.email = payload.email;
        state.userRole = payload.userRole;
      },
    },
  },
});

export default slice.reducer;

export const getUser = (state): User => state[slice.name];

export const getFirstName = (state): string => state[slice.name]?.firstName;
export const getLastName = (state): string => state[slice.name]?.lastName;
export const getEmail = (state): string => state[slice.name]?.email;
export const getIsStaff = (state): boolean => !!state[slice.name]?.staffId;
export const getStaffId = state => state[slice.name]?.staffId;

export const { setUser, updateUser } = slice.actions;
