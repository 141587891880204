import apiFn from 'common/services/api-service';
import { APIResponse } from '~types/ApiReponse';
import { CompanyEvent } from './type';

const api = () => apiFn(`/api/uapi/company-portal/companies`);

export const list = (): APIResponse<CompanyEvent[]> =>
  api()
    .get(null)
    .then(response => response.data);

export default {};
