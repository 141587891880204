import React from 'react';
import { jt, t } from 'ttag';
import styled from 'styled-components';
import { Button, Typography, Space as AntSpace } from 'antd';

import { SUPPORT_EMAIL } from '../../services/support/types';

import AuthLayout from '../Auth/Layout';

const ScopedLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 569px;
  padding: 32px;
  margin: 0 auto;
  text-align: center;
  position: relative;
`;

const Image = styled.img`
  width: 400px;
  height: 275px;
  align-self: center;
`;

const Title = styled(Typography.Title)`
  && {
    align-self: center;
    margin-bottom: 1.5rem;
  }
`;

const Text = styled(Typography.Paragraph)`
  margin-bottom: 24px;
  text-align: center;
`;

const Support = styled.div`
  text-align: center;

  .ant-typography {
    font-size: 12px;
    margin-top: 16px;
  }
`;

const CTA = styled.div<{ scoped?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: ${props => (props.scoped ? 'absolute' : 'relative')};
  left: 50%;
  transform: translateX(-50%);
`;

const Space = styled(AntSpace)`
  width: 100%;
  align-items: center;
`;

interface Props {
  children: React.ReactNode;
  title: string;
  body: string;
  scoped?: boolean;
  support?: boolean;
  image?: string;
}

const Layout = ({
  children,
  title,
  body,
  scoped = false,
  support = false,
  image = null,
}: Props) => {
  const contactSupport = <a key="email" href={`mailto:${SUPPORT_EMAIL}`}>{t`Contact Support.`}</a>;

  if (scoped) {
    return (
      <ScopedLayout>
        <Image src={image} alt="" />
        <Title level={3}>{title}</Title>
        <Text>{body}</Text>
        <div>
          <CTA scoped={scoped}>
            {children}
            {support ? (
              <Button type="default" href={`mailto:${SUPPORT_EMAIL}`}>
                {t`Contact Support`}
              </Button>
            ) : null}
          </CTA>
        </div>
      </ScopedLayout>
    );
  }

  return (
    <AuthLayout>
      <Space direction="vertical" size="middle">
        <Title level={4} style={{ textAlign: 'center' }}>
          {title}
        </Title>
        <Text>{body}</Text>
        <div>
          <CTA>{children}</CTA>
        </div>
        {support ? (
          <Support>
            <Text>{jt`Need help? ${contactSupport}`}</Text>
          </Support>
        ) : null}
      </Space>
    </AuthLayout>
  );
};

export default Layout;
