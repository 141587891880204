export const SUPPORT_EMAIL = 'support@eventmobi.com';
export const SALES_EMAIL = 'am@eventmobi.com';

export enum SupportPhoneUrl {
  DE = 'tel:+49-30-5557343-0',
  EN = 'tel:1-888-296-8415',
}

export enum SupportPhone {
  DE = '+49 (0)30 5557343-0',
  EN = '1-888-296-8415 #2',
}
